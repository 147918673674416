import {CssBaseline} from '@mui/material'
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {useLocation} from "react-router-dom";
import Header from 'components/common/Header';
import TranslationProvider from 'components/providers/TranslationProvider';
import AuthProvider from './components/providers/AuthProvider';
import Views from './components/common/Views';
import CustomThemeProvider from 'components/providers/CustomThemeProvider';
import React, {useEffect} from 'react';
// import { useSelector } from 'react-redux';
import ProsperiaLoader from './components/common/ProsperiaLoader';
import LoadingProvider from './components/providers/LoadingProvider';
import {MuiGrid} from './appStyle';
import {
  UpdateNotificationModal
} from "./components/common/UpdateNotificationModal";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex = 3) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const App = () => {
  const {pathname} = useLocation();
  const NoHeaderRoutes = ["/login", "/reset", "/forgot-password", "/"];
  const parts = pathname.split('/');
  const showHeader = !NoHeaderRoutes.includes(pathname) && parts[parts.length-1] !== 'report';
  const [isUpdateAvailable, setIsUpdateAvailable] = React.useState(false);
  const [appVersion, setAppVersion] = React.useState("");

  useEffect(() => {
    // Fetch the current version from the server
    fetch("/version.json")
      .then((res) => res.json())
      .then((data) => {
        const currentVersion = localStorage.getItem("appVersion");

        if (localStorage.getItem("user") !== null && currentVersion !== data.version) {
          // Notify user about the update
          setIsUpdateAvailable(true);
          setAppVersion(data.version);
        } else {
          setAppVersion(data.version);
          localStorage.setItem("appVersion", data.version);
        }

      })
      .catch((err) => console.error("Failed to fetch version:", err));
  }, [window.location.href]);

  return (
    <CustomThemeProvider>
      <QueryClientProvider client={queryClient}>
        <CssBaseline/>
        <TranslationProvider>
          <AuthProvider>
            <LoadingProvider>
              <MuiGrid container={true} direction='column'>
                {showHeader && <Header/>}
                <Views/>
                <ProsperiaLoader/>
                <UpdateNotificationModal updateAvailable={isUpdateAvailable}
                                         setUpdateAvailable={setIsUpdateAvailable}
                                         updatedAppVersion={appVersion}/>
              </MuiGrid>
            </LoadingProvider>
            <ReactQueryDevtools initialIsOpen={false}/>
          </AuthProvider>
        </TranslationProvider>
      </QueryClientProvider>
    </CustomThemeProvider>
  );
};

export default App;
