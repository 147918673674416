import * as React from 'react';
import {
  Box, Button, Card, CardContent, CardMedia,
  Divider,
  Grid,
  IconButton, Link, List, ListItem, ListItemIcon,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import ellipse from 'assets/img/ellipsi.jpg'
import powered from 'assets/img/powered_by.png'
import EditIcon from '@mui/icons-material/Edit';
import {useTheme} from "../../../../providers/CustomThemeProvider";
import PieChart from "../analysis/tree/PieChart";
import {lighten} from "@mui/material/styles";
import BarGraph from "../analysis/bars/BarGraph";
import {useDispatch, useSelector} from "react-redux";
import {Masonry} from "@mui/lab";
import {
  ArrowBack,
  ArrowUpward,
  CheckCircle,
} from "@mui/icons-material";
import MoreVert from "@mui/icons-material/MoreVert";
import LinkIcon from "@mui/icons-material/Link";
import {MuiPDFIcon} from "../../../../common/styles/navigationMenu";
import {exportPDF} from "./exportToPDF";
import {
  generateScaledPalette,
  getContrastTonality
} from "../../../../../utils/colorPaletteUtils";
import useOrganization from "../../../../../api/hooks/useOrganization";
import {useAuth} from "../../../../providers/AuthProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {
  convertSvgToPngBase64
} from "../specification/GenerateReportModal";
import {getRandomImages} from "./exportToPDF";
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import UserMenu from "../../../login/UserMenu";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {MuiEditor} from "./styles/viewReportPDF";
import {setJsonSchemePDF} from "../../../../../store/appSlice";
import question from "lodash";
import VoicePlayer from "../../../../common/VoicePlayer";
import {AudioControlProvider} from "../../../../providers/VoiceProvider";
import {generateRandomString} from "../../../../../utils/text";

const AuxComponent = ({
                        tabId, question, openQuestionAnalysis, generalContent,
                        activeEditor, matchEditorElement, handleCloseEditor,
                        handleToggleEditor, handleEditorChange, handleDispatch
                      }) => {

  const {theme} = useTheme();
  const selectedPallete = useSelector(state => state.app.treeMapColorScheme);
  const palette = generateScaledPalette(selectedPallete, 10).reverse();

  const policyAux = useSelector(state => state.app?.targetScheme?.policies[tabId]);
  const mainTopicsSection = openQuestionAnalysis[policyAux.longName]
    .sections.find(section => section.sectionName === 'Main topics referenced').additionalData;
  const analysisPerSegment = openQuestionAnalysis[policyAux.longName]
    .sections.find(section => section.sectionName === 'DETAILED ANALYSIS PER SEGMENT').sectionContent;
  const patialConclusions = openQuestionAnalysis[policyAux.longName]
    .sections.find(section => section.sectionName === 'PARTIAL CONCLUSIONS');
  const keyFindingsContent = openQuestionAnalysis[policyAux.longName]
    .sections.find(section => section.sectionName === 'KEY FINDINGS').sectionContent;

  const aux2 = mainTopicsSection.flatMap((item, index) => (
    item.subtopics.length > 0
      ? item.subtopics.map(subtopic => ({
        topic: subtopic.topic,
        value: subtopic.count,
      }))
      : [{topic: item.topic, value: item.count}]
  ))

  const scheme = useSelector(state => state.app?.targetScheme);
  const imagesPartialConclusion = getRandomImages(scheme, 6);
  const barAggregationDataAux = {data: aux2, indexBy: "topic", keys: []}
  const collection = useSelector(state => state.app?.targetCollection);
  const test = collection.variables.filter((item) => item.category === 'categorical')
  const topics = [...mainTopicsSection];
  const allTopics = topics.sort((a, b) => b.representativity - a.representativity)
  const iconButtonStyle = {
    verticalAlign: 'baseline',
    marginLeft: '8px',
    border: '1px solid #e0e0e0',
    width: '26px',
    height: '26px',
  };
  const iconStyle = {
    width: '16px',
    height: '16px',
  };

  const getDataVerticalGraph = (data) => {
    const keys = data[0].categories.map((item, index) => (item.name))
    const objs = data.map((item, index) => (
      {
        topic: item.topic,
        value: item.value,
        ...keys.reduce((acc, key, index) => {
          acc[item.collapsed_categories[index]?.name] = item.collapsed_categories[index]?.percentage;
          return acc;
        }, {})
      }
    ))
    const arrayObjs = {data: objs, indexBy: "topic", keys: keys}
    return arrayObjs
  }

  function extractListItemsFromHTML(htmlContent) {
    const instance = new DOMParser();
    const doc = instance.parseFromString(htmlContent, "text/html");
    const items = doc.querySelectorAll("li");

    return Array.from(items)
      .map(li => {
        const strongElement = li.querySelector("strong");
        const title = strongElement ? strongElement.textContent.trim() : "";

        if (!title) return null; // Si title está vacío, no se devuelve el objeto

        // Clonar el <li> para eliminar las sublistas sin modificar el DOM original
        const liClone = li.cloneNode(true);
        liClone.querySelectorAll("ul").forEach(ul => ul.remove());

        const argument = strongElement
          ? liClone.textContent.replace(strongElement.textContent, "").trim()
          : liClone.textContent.trim();

        // Obtener elementos dentro de sublistas
        const subItems = Array.from(li.querySelectorAll("ul > li")).map(subLi => subLi.textContent.trim());

        return {title, argument, subItems};
      })
      .filter(Boolean); // Filtra los valores nulos
  }


  const isDarkColor = (hexColor) => {
    hexColor = hexColor.replace("#", "");

    let r = parseInt(hexColor.substring(0, 2), 16);
    let g = parseInt(hexColor.substring(2, 4), 16);
    let b = parseInt(hexColor.substring(4, 6), 16);

    let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance < 0.5;
  };

  const getIconColor = (backgroundColor) => {
    return isDarkColor(backgroundColor) ? "white" : "black";
  };

  const matchTopic = (topic) => {
    const topicMatched = policyAux.topics.find((item) => item.topic === topic)
    return topicMatched.icon
  }

  const rgbToHex = (rgbString) => {
    const match = rgbString.match(/\d+/g);

    if (!match || match.length !== 3) {
      throw new Error("Formato incorrecto. Debe ser 'rgb(R, G, B)'");
    }
    const [r, g, b] = match.map(Number);
    const toHex = (c) => c.toString(16).padStart(2, '0');

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
  };

  const subtopicMap = (subtopics, color) => {
    return (
      <>
        {
          subtopics[0].sample_answers.map((value) => (
            <p style={{
              backgroundColor: color,
              borderRadius: '8px',
              padding: '5px 10px',
              color: 'white',
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '14px'
            }}>
        <span
          style={{
            filter: getIconColor(rgbToHex(color)) === "black" ?
              "invert(1)" : "invert(0)"
          }}>
          {value}
        </span>
            </p>
          ))
        }
      </>
    )
  }

  const rid = generateRandomString(10);
  const audioKey = `audio-${rid}`;
  return (
    <>
      <Grid id='sectionGraphH' container sx={{marginTop: '50px'}}>
        <Grid item xs={6} sx={{
          height: '580px',
          borderTopRightRadius: '30px',
          borderBottomRightRadius: '120px',
          padding: '36px 24px 0px 48px',
          backgroundColor: generalContent.primary_color
        }} display='flex'
              direction='column'
              justifyContent="flex-start"
              alignItems="center">
          <Typography variant="h4" style={{
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 500,
            fontSize: '24px',
            alignSelf: 'flex-start',
            marginLeft: '7.5rem'
          }}>
            {question}
          </Typography>
          <Typography variant="h4" style={{
            color: 'white', fontFamily: 'Raleway',
            fontWeight: 700, fontSize: '42px', marginLeft: '7.5rem'
          }}>
            {`3.${tabId + 1}.${Object.keys(openQuestionAnalysis)[tabId]}`}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{height: '580px', padding: '0px 40px'}}>
          <Typography variant="h4" style={{
            color: '#212121', fontFamily: 'Montserrat',
            fontWeight: 500, fontSize: '24px'
          }}>
            Representativity of each topic (%)
          </Typography>
          <BarGraph
            data={barAggregationDataAux}
            policy={policyAux}
            segmentationVariables={test}
            callingComponent={"ViewReportPDF"}
          />
        </Grid>
      </Grid>

      {/*6. Key Findings*/}
      <section id='sectionKeyFindings' style={{height: 'auto'}}>
        <Grid item xs={6} style={{
          textAlign: 'center',
          marginLeft: '-5.6rem',
          marginTop: '50px'
        }}>
          <Typography variant="h4" style={{
            color: '#212121', fontFamily: 'raleway',
            fontWeight: 400, fontSize: '35px', marginBottom: '24px'
          }}>
            {`3.${tabId + 1}.1`} Key <span
            style={{color: generalContent.primary_color}}>Findings</span>
          </Typography>
        </Grid>
        <Grid
          container
          spacing={2}
          display='flex'
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Grid item style={{width: '551px', height: 'auto'}}>
            {keyFindingsContent.slice(0, 2).map((item, index) => (
              <>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between'
                }}>
                  <Typography style={{
                    color: '#424242', fontFamily: 'Raleway',
                    fontWeight: 700, fontSize: '20px'
                  }}>
                    {item.sectionName}
                  </Typography>
                  <IconButton edge="end" size="small" aria-label="edit"
                              sx={iconButtonStyle}>
                    {activeEditor && matchEditorElement === index ? (
                      <CloseIcon style={{
                        ...iconStyle,
                        color: generalContent.primary_color
                      }} onClick={handleCloseEditor}/>
                    ) : (
                      <EditIcon style={iconStyle}
                                onClick={() => handleToggleEditor(index)}/>
                    )}
                  </IconButton>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                  {activeEditor && matchEditorElement === index ? (
                    <MuiEditor elevation={0}>
                      <Box sx={{height: 'auto', width: '100%'}}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={item.sectionContent}
                          config={{
                            removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                            toolbar: [
                              'heading', '|', 'bold', 'italic', 'underline', '|',
                              'bulletedList', 'numberedList', '|',
                              'undo', 'redo'
                            ],
                          }}
                          onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', index, 'keyFindings')}
                        />
                      </Box>
                      <Button
                        startIcon={(<CheckIcon/>)}
                        variant='outlined'
                        sx={{
                          marginTop: '10px',
                          borderRadius: '20px',
                          height: '37px',
                          color: generalContent.primary_color,
                          borderColor: generalContent.primary_color,
                          '&:hover': {
                            borderColor: generalContent.primary_color,
                            backgroundColor: lighten(generalContent.primary_color, 0.8),
                          }
                        }}
                        onClick={() => handleDispatch('open_question_analysis', index)}
                      >
                        aceptar
                      </Button>
                    </MuiEditor>
                  ) : (
                    <Typography variant="body1" style={{
                      color: '#616161', fontFamily: 'Montserrat',
                      fontWeight: 400, fontSize: '16px',
                      display: 'inline'
                    }}
                                dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                    </Typography>
                  )}
                </Box>
              </>
            ))}
          </Grid>

          <Grid item style={{
            width: '551px', height: 'auto', backgroundColor: '#FAFAFA',
            borderRadius: '24px', paddingLeft: '24px', paddingRight: '24px'
          }}>
            {keyFindingsContent.slice(2, 4).map((item, index) => (
              <>
                {index === 0 ? (
                  <>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between'
                    }}>
                      <Typography style={{
                        color: '#424242', fontFamily: 'Raleway',
                        fontWeight: 700, fontSize: '20px'
                      }}>
                        {item.sectionName}
                      </Typography>
                      <IconButton edge="end" size="small" aria-label="edit"
                                  sx={iconButtonStyle}>
                        {activeEditor && matchEditorElement === index + 2 ? (
                          <CloseIcon style={{
                            ...iconStyle,
                            color: generalContent.primary_color
                          }} onClick={handleCloseEditor}/>
                        ) : (
                          <EditIcon style={iconStyle}
                                    onClick={() => handleToggleEditor(index + 2)}/>
                        )}
                      </IconButton>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                      {activeEditor && matchEditorElement === index + 2 ? (
                        <MuiEditor elevation={0}>
                          <Box sx={{height: 'auto', width: '100%'}}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.sectionContent}
                              config={{
                                removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                                toolbar: [
                                  'heading', '|', 'bold', 'italic', 'underline', '|',
                                  'bulletedList', 'numberedList', '|',
                                  'undo', 'redo'
                                ],
                              }}
                              onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', index + 2, 'keyFindings')}
                            />
                          </Box>
                          <Button
                            startIcon={(<CheckIcon/>)}
                            variant='outlined'
                            sx={{
                              marginTop: '10px',
                              borderRadius: '20px',
                              height: '37px',
                              color: generalContent.primary_color,
                              borderColor: generalContent.primary_color,
                              '&:hover': {
                                borderColor: generalContent.primary_color,
                                backgroundColor: lighten(generalContent.primary_color, 0.8),
                              }
                            }}
                            onClick={() => handleDispatch('open_question_analysis', index + 2)}
                          >
                            aceptar
                          </Button>
                        </MuiEditor>
                      ) : (
                        <Typography variant="body1" style={{
                          color: '#616161', fontFamily: 'Montserrat',
                          fontWeight: 400, fontSize: '16px',
                          display: 'inline'
                        }}
                                    dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                        </Typography>
                      )}
                    </Box>
                  </>
                ) : (
                  <div style={{
                    backgroundColor: generalContent.primary_color,
                    padding: '22px 30px', borderRadius: '30px'
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between'
                    }}>
                      <Typography style={{
                        color: 'white', fontFamily: 'Raleway',
                        fontWeight: 700, fontSize: '20px'
                      }}>
                        {item.sectionName}
                      </Typography>
                      <IconButton edge="end" size="small" aria-label="edit"
                                  sx={iconButtonStyle}>
                        {activeEditor && matchEditorElement === index + 2 ? (
                          <CloseIcon style={{...iconStyle, color: 'white'}}
                                     onClick={handleCloseEditor}/>
                        ) : (
                          <EditIcon style={{...iconStyle, color: 'white'}}
                                    onClick={() => handleToggleEditor(index + 2)}/>
                        )}
                      </IconButton>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                      {activeEditor && matchEditorElement === index + 2 ? (
                        <MuiEditor elevation={0}>
                          <Box sx={{height: 'auto', width: '100%'}}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.sectionContent}
                              config={{
                                removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                                toolbar: [
                                  'heading', '|', 'bold', 'italic', 'underline', '|',
                                  'bulletedList', 'numberedList', '|',
                                  'undo', 'redo'
                                ],
                              }}
                              onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', index + 2, 'keyFindings')}
                            />
                          </Box>
                          <Button
                            startIcon={(<CheckIcon/>)}
                            variant='outlined'
                            sx={{
                              marginTop: '10px',
                              borderRadius: '20px',
                              height: '37px',
                              color: generalContent.primary_color,
                              borderColor: generalContent.primary_color,
                              '&:hover': {
                                borderColor: generalContent.primary_color,
                                backgroundColor: lighten(generalContent.primary_color, 0.8),
                              }
                            }}
                            onClick={() => handleDispatch('open_question_analysis', index)}
                          >
                            aceptar
                          </Button>
                        </MuiEditor>
                      ) : (
                        <Typography variant="body1" style={{
                          color: 'white', fontFamily: 'Montserrat',
                          fontWeight: 400, fontSize: '16px',
                          display: 'inline'
                        }}
                                    dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                        </Typography>
                      )}
                    </Box>
                  </div>
                )}
              </>
            ))}
          </Grid>
          <Divider style={{
            borderColor: '#E0E0E0',
            width: '86%',
            margin: ' 100px 0px 50px 40px'
          }}/>
        </Grid>
      </section>

      {/*7. Citas*/}
      <section id='sectionQuotes' style={{height: 'auto'}}>
        <Grid item xs={6} style={{textAlign: 'center', marginLeft: '-2.6rem'}}>
          <Typography variant="h4" style={{
            color: '#212121', fontFamily: 'raleway',
            fontWeight: 400, fontSize: '35px', marginBottom: '24px'
          }}>
            {`3.${tabId + 1}.2`} Main topics <span
            style={{color: generalContent.primary_color}}>referenced</span>
          </Typography>
        </Grid>
        <Grid
          container
          spacing={2}
          display='flex'
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Grid item style={{width: '600px', height: 'auto'}}>
            {allTopics.map((item, index) => index % 2 === 0 &&
              <div key={index} style={{marginBottom: '30px'}}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between'
                }}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Box sx={{
                      backgroundColor: palette[index],
                      width: '43px',
                      height: '43px',
                      borderRadius: '4px',
                      padding: '4.5px'
                    }}>

                      <img
                        alt="icon"
                        width="28"
                        height="28"
                        src={`data:image/svg+xml;base64,${matchTopic(item.topic)}`}
                        style={{
                          filter: getIconColor(rgbToHex(palette[index])) === "white"
                            ? "invert(1)" : "invert(0)",
                          width: '28px',
                          height: '28px',
                          margin: '2px',
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '5px'
                    }}>
                      <Typography style={{
                        color: '#616161', fontFamily: 'Montserrat',
                        fontWeight: 700, fontSize: '20px'
                      }}>
                        {item.topic} ({item.representativity}%)
                      </Typography>
                      <Typography style={{
                        color: '#424242', fontFamily: 'Raleway',
                        fontWeight: 400, fontSize: '14px'
                      }}>
                        <span>SUBTOPICS: {item.subtopics.length}</span>
                        <span
                          style={{marginLeft: '18px'}}>QUOTES: {item?.sample_answers?.length || 0}</span>
                      </Typography>
                    </div>
                  </div>
                </Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column'
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '5px'
                  }}>
                    <Typography style={{
                      color: '#616161', fontFamily: 'Montserrat',
                      fontWeight: 700, fontSize: '20px'
                    }}>
                      Summary:
                    </Typography>
                    <Typography style={{
                      color: '#424242', fontFamily: 'Raleway',
                      fontWeight: 400, fontSize: '14px'
                    }}>
                      {item.summary}
                    </Typography>
                  </div>
                  <div style={{marginLeft: '5px'}}>
                    <Typography style={{
                      color: '#616161', fontFamily: 'Montserrat',
                      fontWeight: 700, fontSize: '20px'
                    }}>
                      Quotes:
                    </Typography>
                  </div>
                  <div style={{maxHeight: '300px', overflowY: 'auto'}}>
                    {item.sample_answers && item.sample_answers.length > 0 ? (
                      item.sample_answers.map((value, indice) => (
                        <p style={{
                          backgroundColor: palette[index],
                          borderRadius: '8px',
                          padding: '5px 10px',
                          color: 'white',
                          fontFamily: 'Montserrat',
                          fontWeight: 400,
                          fontSize: '14px'
                        }}>
                        <span style={{
                          filter: getIconColor(rgbToHex(palette[index])) === "black"
                            ? "invert(1)" : "invert(0)"
                        }}>
                          {value}
                        </span>
                          <AudioControlProvider>
                            {(item.audio && item.audio[indice] !== null && item.audio[indice] !== 'None' && item.audio[indice] !== undefined) && (
                              <VoicePlayer
                                audio={item.audio[indice]}
                                key={`${audioKey}-${indice}`}
                                audioKey={`${audioKey}-${indice}`}
                                color={getContrastTonality(palette[index]) === 'dark' ? '#212121' : '#FFFFFF'}
                                theme={getContrastTonality(palette[index])}
                              />
                            )}
                          </AudioControlProvider>
                        </p>
                      ))
                    ) : (
                      <>
                        {subtopicMap(item.subtopics, palette[index])}
                      </>
                    )}
                  </div>
                </Box>
              </div>)}
          </Grid>

          <Grid item style={{width: '600px', height: 'auto'}}>
            {allTopics.map((item, index) => index % 2 !== 0 &&
              <div key={index} style={{marginBottom: '30px'}}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between'
                }}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Box sx={{
                      backgroundColor: palette[index],
                      width: '43px',
                      height: '43px',
                      borderRadius: '4px', padding: '4.5px'
                    }}>
                      <img
                        alt="icon"
                        width="28"
                        height="28"
                        src={`data:image/svg+xml;base64,${matchTopic(item.topic)}`}
                        style={{
                          filter: getIconColor(rgbToHex(palette[index])) === "white"
                            ? "invert(1)" : "invert(0)",
                          width: '28px',
                          height: '28px',
                          margin: '2px',
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '5px'
                    }}>
                      <Typography style={{
                        color: '#616161', fontFamily: 'Montserrat',
                        fontWeight: 700, fontSize: '20px'
                      }}>
                        {item.topic} ({item.representativity}%)
                      </Typography>
                      <Typography style={{
                        color: '#424242', fontFamily: 'Raleway',
                        fontWeight: 400, fontSize: '14px'
                      }}>
                        <span>SUBTOPICS: {item.subtopics.length}</span>
                        <span
                          style={{marginLeft: '18px'}}>QUOTES: {item?.sample_answers?.length || 0}</span>
                      </Typography>
                    </div>
                  </div>
                </Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column'
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '5px'
                  }}>
                    <Typography style={{
                      color: '#616161', fontFamily: 'Montserrat',
                      fontWeight: 700, fontSize: '20px'
                    }}>
                      Summary:
                    </Typography>
                    <Typography style={{
                      color: '#424242', fontFamily: 'Raleway',
                      fontWeight: 400, fontSize: '14px'
                    }}>
                      {item.summary}
                    </Typography>
                  </div>
                  <div style={{marginLeft: '5px'}}>
                    <Typography style={{
                      color: '#616161', fontFamily: 'Montserrat',
                      fontWeight: 700, fontSize: '20px'
                    }}>
                      Quotes:
                    </Typography>
                  </div>
                  <div style={{maxHeight: '300px', overflowY: 'auto'}}>
                    {item.sample_answers && item.sample_answers.length > 0 && (
                      item.sample_answers.map((value) => (
                        <p style={{
                          backgroundColor: palette[index],
                          borderRadius: '8px',
                          padding: '5px 10px',
                          color: 'white',
                          fontFamily: 'Montserrat',
                          fontWeight: 400,
                          fontSize: '14px'
                        }}>
                        <span style={{
                          filter: getIconColor(rgbToHex(palette[index])) === "black"
                            ? "invert(1)" : "invert(0)"
                        }}>
                          {value}
                        </span>
                        </p>
                      ))
                    )}
                  </div>
                </Box>
              </div>)}
          </Grid>

          <Divider style={{
            borderColor: '#E0E0E0',
            width: '86%',
            margin: ' 50px 0px 50px 40px'
          }}/>
        </Grid>
      </section>

      {/*8. Grafico de barras vertical*/}
      <section id='sectionGraphBarV' style={{height: 'auto'}}>
        <Grid item xs={6} style={{textAlign: 'center', marginLeft: '2.5rem'}}>
          <Typography variant="h4" style={{
            color: '#212121', fontFamily: 'raleway',
            fontWeight: 400, fontSize: '35px', marginBottom: '24px'
          }}>
            {`3.${tabId + 1}.3`} Detailed Analysis <span
            style={{color: generalContent.primary_color}}>Per Segment</span>
          </Typography>
        </Grid>
        <Grid
          container
          spacing={2}
          display='flex'
          justifyContent="space-evenly"
          alignItems="center"
        >
          {analysisPerSegment.map((item, index) => (
            <>
              <Grid item style={{width: '80%', height: 'auto'}}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between'
                }}>
                  <Typography style={{
                    color: '#424242', fontFamily: 'Raleway',
                    fontWeight: 700, fontSize: '20px'
                  }}>
                    {item.sectionName}
                  </Typography>
                  <IconButton edge="end" size="small" aria-label="edit"
                              sx={iconButtonStyle}>
                    {activeEditor && matchEditorElement === index ? (
                      <CloseIcon style={{
                        ...iconStyle,
                        color: generalContent.primary_color
                      }} onClick={handleCloseEditor}/>
                    ) : (
                      <EditIcon style={iconStyle}
                                onClick={() => handleToggleEditor(index)}/>
                    )}
                  </IconButton>
                </Box>
                <Grid container sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'row'
                }}>
                  {activeEditor && matchEditorElement === index ? (
                    <MuiEditor elevation={0}>
                      <Box sx={{height: 'auto', width: '100%'}}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={item.sectionContent}
                          config={{
                            removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                            toolbar: [
                              'heading', '|', 'bold', 'italic', 'underline', '|',
                              'bulletedList', 'numberedList', '|',
                              'undo', 'redo'
                            ],
                          }}
                          onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', index, 'analysisPerSegment')}
                        />
                      </Box>
                      <Button
                        startIcon={(<CheckIcon/>)}
                        variant='outlined'
                        sx={{
                          marginTop: '10px',
                          borderRadius: '20px',
                          height: '37px',
                          color: generalContent.primary_color,
                          borderColor: generalContent.primary_color,
                          '&:hover': {
                            borderColor: generalContent.primary_color,
                            backgroundColor: lighten(generalContent.primary_color, 0.8),
                          }
                        }}
                        onClick={() => handleDispatch('open_question_analysis', index)}
                      >
                        aceptar
                      </Button>
                    </MuiEditor>
                  ) : (
                    <>
                      {extractListItemsFromHTML(item.sectionContent).map((element, index) => (
                        <Grid item xs={6} key={index}>
                          <p style={{
                            paddingLeft: '24px',
                            color: '#616161',
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            fontSize: '16px'
                          }}>
                        <span style={{
                          color: generalContent.primary_color,
                          fontWeight: 700
                        }}>
                          {element.title}
                        </span>
                            {element.argument}
                          </p>
                          {element.subItems.length > 0 && (
                            <ul style={{paddingLeft: '40px', marginTop: '5px'}}>
                              {element.subItems.map((sub, subIndex) => (
                                <li key={subIndex} style={{
                                  fontSize: '14px',
                                  fontFamily: 'Montserrat',
                                  color: '#424242'
                                }}>
                                  {sub}
                                </li>
                              ))}
                            </ul>
                          )}
                        </Grid>
                      ))}
                    </>
                  )}

                  <Grid item xs={12} sx={{height: 550, paddingLeft: '40px'}}>
                    <BarGraph
                      layout='vertical'
                      callingComponent='ViewReportPDF'
                      data={getDataVerticalGraph(item.additionalData)}
                      segmentName={item.sectionName}
                      policy={policyAux}
                      segmentationVariables={test}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))}
          <Divider
            style={{borderColor: '#E0E0E0', width: '86%', marginTop: '100px'}}/>
        </Grid>
      </section>

      {/*9. Conclusiones parciales*/}
      <section id='sectionPartialConclutions' style={{height: 'auto'}}>
        <Grid
          container
          spacing={2}
          display='flex'
          justifyContent="space-evenly"
          alignItems="center"
          sx={{marginTop: '50px'}}
        >
          <Grid item style={{width: '651px', height: '560px'}}>
            <Box sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}>
              <Typography variant="h4" style={{
                color: '#212121', fontFamily: 'raleway',
                fontWeight: 400, fontSize: '35px', marginBottom: '24px'
              }}>
                {`3.${tabId + 1}.4`} Partial <span
                style={{color: generalContent.primary_color}}>Conclusions</span>
              </Typography>
              <IconButton edge="end" size="small" aria-label="edit"
                          sx={iconButtonStyle}>
                {activeEditor ? (
                  <CloseIcon
                    style={{...iconStyle, color: generalContent.primary_color}}
                    onClick={handleCloseEditor}/>
                ) : (
                  <EditIcon style={iconStyle}
                            onClick={() => handleToggleEditor(-1)}/>
                )}
              </IconButton>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column'
            }}>
              <Typography variant="body1" style={{
                color: '#616161', fontFamily: 'Montserrat',
                fontWeight: 400, fontSize: '16px',
                display: 'inline'
              }}>
                Based on the analysis, the following actionable
                insights are recommended:
              </Typography>
              {activeEditor ? (
                <MuiEditor elevation={0}>
                  <Box sx={{height: 'auto', width: '100%'}}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={patialConclusions.sectionContent}
                      config={{
                        removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                        toolbar: [
                          'heading', '|', 'bold', 'italic', 'underline', '|',
                          'bulletedList', 'numberedList', '|',
                          'undo', 'redo'
                        ],
                      }}
                      onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', 3, 'partialConclusions')}
                    />
                  </Box>
                  <Button
                    startIcon={(<CheckIcon/>)}
                    variant='outlined'
                    sx={{
                      marginTop: '10px',
                      borderRadius: '20px',
                      height: '37px',
                      color: generalContent.primary_color,
                      borderColor: generalContent.primary_color,
                      '&:hover': {
                        borderColor: generalContent.primary_color,
                        backgroundColor: lighten(generalContent.primary_color, 0.8),
                      }
                    }}
                    onClick={() => handleDispatch('open_question_analysis', 3)}
                  >
                    aceptar
                  </Button>
                </MuiEditor>
              ) : (
                <Typography variant="body1" style={{
                  color: '#616161', fontFamily: 'Montserrat',
                  fontWeight: 400, fontSize: '16px',
                  display: 'inline'
                }}
                            dangerouslySetInnerHTML={{__html: patialConclusions.sectionContent}}>
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item alignSelf='flex-start'>
            <Box
              sx={{
                width: "100%",
                maxWidth: 600,
                borderRadius: "35%",
                overflow: "hidden",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Grid container spacing={0.5} sx={{justifyContent: "center"}}>
                {imagesPartialConclusion.map((image, index) => (
                  <Grid item xs={6} key={index}>
                    <Box
                      component="img"
                      src={image}
                      alt={`img-${index}`}
                      sx={{
                        width: '99%',
                        height: 137,
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </section>
    </>

  )
}

const ReportPDF = () => {
  const {theme} = useTheme();
  const policyAux = useSelector(state => state.app?.targetScheme?.policies[0]);
  const questions = useSelector(state => state.app?.targetScheme?.policies);
  const [isShowOptions, setIsShowOptions] = React.useState(false);
  const dispatch = useDispatch();
  const scheme = useSelector(state => state.app?.targetScheme);
  const dataReport = useSelector(state => state.app?.targetScheme.jsonSchemePDF);
  const executiveSummary = dataReport.executive_summary;
  const openQuestionAnalysis = dataReport.open_question_analysis;
  const segmentsAnalysis = dataReport.segments_analysis;
  const conclusions = dataReport.conclusions;
  const generalContent = dataReport.general;

  const iconButtonStyle = {
    verticalAlign: 'baseline',
    marginLeft: '8px',
    border: '1px solid #e0e0e0',
    width: '26px',
    height: '26px',
  };
  const iconStyle = {
    width: '16px',
    height: '16px',
  };
  const [activeTab, setActiveTab] = React.useState(0);
  const [indicatorWidth, setIndicatorWidth] = React.useState(0);

  const tabs = Object.keys(openQuestionAnalysis).map((item, index) => (
    {id: index, value: `Question ${index + 1}`}
  ))

  React.useEffect(() => {
    const updateIndicatorWidth = () => {
      const selectedTab = document.querySelector('.Mui-selected');
      if (selectedTab) {
        setIndicatorWidth(selectedTab.offsetWidth);
      }
    };

    updateIndicatorWidth();

    window.addEventListener('resize', updateIndicatorWidth);
    return () => window.removeEventListener('resize', updateIndicatorWidth);
  }, [activeTab]);

  const tabContent = (openQuestionAnalysis) => (
    <div id={getIdSeccion('', Object.keys(openQuestionAnalysis)[activeTab])}>
      {<AuxComponent
        tabId={activeTab}
        generalContent={generalContent}
        question={tabs[activeTab].value}
        openQuestionAnalysis={openQuestionAnalysis}
        activeEditor={activeEditor}
        matchEditorElement={matchEditorElement}
        handleCloseEditor={handleCloseEditor}
        handleToggleEditor={handleToggleEditor}
        handleEditorChange={handleEditorChange}
        handleDispatch={handleDispatch}
      />}
    </div>
  )
  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };
  const images3 = getRandomImages(scheme, 20);
  const imagesConclusion = getRandomImages(scheme, 4);
  const [isFixed, setIsFixed] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const selectedPallete = useSelector(state => state.app.treeMapColorScheme);
  const palette = generateScaledPalette(selectedPallete, 10).reverse();
  const {user} = useAuth();
  const location = useLocation();
  const orgId = location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const {data: organization} = useOrganization(orgId);
  const [schemeModified, setSchemeModified] = React.useState(null);
  const [schemeColor, setSchemeColor] = React.useState(false);
  const [showGoHome, setShowGoHome] = React.useState(false);
  const navigate = useNavigate();
  const isPublic = user.type !== 'level_0'

  const handleScroll = () => {
    setIsFixed(window.scrollY > 3885);
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const handleCopyUrl = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1200);
    }).catch(err => {
      console.error('Error al copiar la URL:', err);
    });
  };
  const updateSchemaWithPngIcons = async (schema, color) => {
    for (const policy of schema.policies) {
      for (const [index, topic] of policy.topics.entries()) {
        if (topic.icon) {
          topic.icon = await convertSvgToPngBase64(topic.icon, color === 'gray' ? '#616161' : getContrastTonality(palette[index]) === 'dark' ? '#212121' : '#FFFFFF');
        }
      }
    }
    return schema;
  };
  React.useEffect(() => {
    const convertIcons = async (color) => {
      const clonedSchema = JSON.parse(JSON.stringify(scheme));
      const updatedSchema = await updateSchemaWithPngIcons(clonedSchema, color);
      if (color === 'gray') {
        setSchemeModified(updatedSchema);
      } else {
        setSchemeColor(updatedSchema);
      }
    };
    if (scheme.policies.length > 0) {
      convertIcons('gray');
      convertIcons(palette);
    }
  }, [scheme]);
  React.useEffect(() => {
    const handleScroll = () => {
      setShowGoHome(window.scrollY > window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const backToAnalysis = () => {
    const currentUrl = window.location.pathname;
    const newUrl = currentUrl.replace('/report', '');
    navigate(newUrl);
  }

  const methodAux = (segments) => {
    let aux = [...segments]
    let dataSegment = [];
    const valueTotal = segments.reduce((sum, obj) => sum + (obj.occurrences || 0), 0);
    const sortedCategoires = aux.sort((a, b) => b.occurrences - a.occurrences);
    const topSeven = sortedCategoires.slice(0, 7);
    const others = sortedCategoires.slice(7);
    const otherOccurrences = others.reduce((sum, obj) => sum + (obj.occurrences || 0), 0);

    topSeven.forEach((segment, index) => {
      dataSegment.push({
        id: `${segment.name}`,
        label: `${segment.name} (${segment.occurrences})`,
        value: Math.round(segment.occurrences * 100 / valueTotal, 1),
        color: palette[index % palette.length]
      });
    })

    if (others.length > 0) {
      dataSegment.push({
        label: `Other (${otherOccurrences})`,
        value: Math.round(otherOccurrences * 100 / valueTotal, 1),
        color: palette[7 % palette.length] // or however you want to pick the color
      });
    }

    return dataSegment
  }

  const getIdSeccion = (nameSection, nameItem) => {
    const idSection = nameSection !== '' ? `${nameSection}-${nameItem}` : `${nameItem}`
    return idSection.toLowerCase().replaceAll(' ', '_')
  }

  const sections = [
    {id: "sectionExecutiveSummary", title: "Executive Summary", root: true},
    {id: "executiveSummary-0", title: "Concise Overview", root: false},
    {id: "executiveSummary-1", title: "Actionable insights", root: false},
    {id: "executiveSummary-2", title: "Key findings", root: false},
    {id: "executiveSummary-3", title: "Conclusions", root: false},
    {id: "sectionGraphPie", title: "Relevant Segments", root: true},
    ...segmentsAnalysis.sections.map((item, index) => (
      {
        id: getIdSeccion('sectionGraphPie', item.sectionName),
        title: item.sectionName,
        root: false
      }
    )),
    {id: "sectionGraphBarH", title: "Responses Analysis", root: true},
    ...Object.keys(openQuestionAnalysis).map((item, index) => (
      {
        id: getIdSeccion('', item),
        title: `Question ${index + 1} - ${item}`,
        index: index,
        root: false
      }
    )),
    {id: "sectionConclutions", title: "Conclutions", root: true},
    {id: "sectionGallery", title: "Gallery", root: true},
  ];

  const eventAux = (id, index) => {
    if (id.includes('?')) {
      setActiveTab(index)
    }
  }
  const handleGoToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({behavior: "smooth", block: "start"});
    }
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return null;
    const date = new Date(dateStr.trim());
    const options = {month: 'long', day: 'numeric', year: 'numeric'};
    const formattedDate = date.toLocaleDateString('en-US', options);

    return `${formattedDate}`;
  }
  const [description, setDescription] = React.useState(generalContent.description || '');
  const [activeEditor, setActiveEditor] = React.useState(false);
  const [matchEditorElement, setMatchEditorElement] = React.useState(null);
  const handleToggleEditor = (index) => {
    setActiveEditor(true)
    setMatchEditorElement(matchEditorElement === index ? null : index);
  };

  const handleEditorChange = (event, editor, key, index, sectionName = '') => {
    let aux, objConclution;
    if (key === 'executive_summary') {
      aux = [...executiveSummary.sections];
    } else if (key === 'segments_analysis') {
      aux = [...segmentsAnalysis.sections];
    } else if (key === 'open_question_analysis') {
      aux = [...openQuestionAnalysis[policyAux.longName].sections];
      aux[index] = {...aux[index], sectionContent: editor.getData()};
      setDescription({
        question: Object.keys(openQuestionAnalysis)[activeTab],
        sectionName: sectionName,
        dataUpdated: editor.getData()
      });
      return;
    } else if (key === 'conclusions') {
      objConclution = editor.getData().toString().replace(/<\/?p>/g, "").split(':');
      aux = [...conclusions.sections[0].sectionContent];
      aux[index] = {
        ...aux[index],
        conclusionTitle: objConclution[0],
        conclusionContent: objConclution[1]
      };
      setDescription(aux[index]);
      return
    }
    aux[index] = {...aux[index], sectionContent: editor.getData()};
    setDescription(editor.getData());
  };
  const handleDispatch = (key, index) => {
    dispatch(setJsonSchemePDF({key: key, index: index, data: description}));
    setActiveEditor(false);
  }

  const handleCloseEditor = () => {
    setActiveEditor(false);
    setMatchEditorElement(null);
  }

  const matchQuestion = (questionJson) => {
    const question = questions.find((item) => item.longName === questionJson)
    return (
      <Grid container spacing={3} sx={{marginTop: '24px'}}>
        {question.topics.map((item, index) => (
          <Grid item xs={4} sx={{paddingTop: '0px !important'}}
                key={index}>
            <Card sx={{maxWidth: 345}} elevation={0}>
              <CardMedia
                sx={{height: 140}}
                image={item.image_url}
                title="green iguana"
              />
              <CardContent>
                <Typography variant="h4" style={{
                  color: '#424242', fontFamily: 'Raleway',
                  fontWeight: 700, fontSize: '16px'
                }}>
                  {item.topic}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <>
      <section id='sectionInitial' style={{
        height: '100vh',
        backgroundColor: 'white',
        overflow: 'hidden'
      }}>
        <Box sx={{width: '100%', minHeight: 217}}>
          <Masonry columns={5} spacing={0.5}>
            {images3.map((src, index) => (
              <div key={index}>
                <img
                  src={src}
                  alt={`Gallery ${index}`}
                  loading="lazy"
                  style={{
                    display: "block",
                    width: "100%",
                    height: index === 0 || index === 2 || index === 4 ? '90px' : "auto",
                    objectFit: "scale-down"
                  }}
                />
              </div>
            ))}
          </Masonry>
          <IconButton
            sx={{
              width: 205,
              height: 38,
              borderRadius: '20px',
              backgroundColor: `${generalContent.primary_color}`,
              position: 'absolute',
              top: 16,
              left: 24,
              fontFamily: 'Montserrat',
              fontSize: '14px',
              color: 'white',
              fontWeight: 500,
              '&:hover': {
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                transition: 'box-shadow ease-in-out',
                backgroundColor: `${generalContent.primary_color}`,
              }
            }}
            onClick={backToAnalysis}
          >
            <ArrowBack sx={{color: 'white'}}/> BACK TO ANALYSIS
          </IconButton>
          {isPublic &&
            <div
              style={{
                height: '43px',
                width: '165px',
                backgroundColor: `${generalContent.primary_color}`,
                borderRadius: 24,
                position: 'absolute',
                top: 16,
                right: 24,
                display: 'flex',
                alignItems: 'center',
                padding: '4px 18px 4px -8px'
              }}>
              <UserMenu/>
            </div>}
        </Box>
        <div style={{
          position: 'absolute',
          background: `linear-gradient(to bottom, ${generalContent.primary_color} 74%, #EEEEEE 50%)`,
          zIndex: 999,
          padding: '24px 24px', left: '44%', top: '23%', borderRadius: '50%'
        }}>
          <div
            style={{
              width: 167,
              height: 167,
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%'
            }}>
            <img
              src={organization?.attrs.profile_img_url}
              alt="Organization Logo"
              style={{
                width: "100%",
                height: '100%',
                borderRadius: '50%',
                borderColor:'transparent',
                objectFit: 'scale-down'
              }}
            />
          </div>
        </div>
        <Box
          sx={{
            position: "relative",
            mt: -15,
            backgroundColor: "white",
            clipPath: "polygon(50% -6%, 100% 100%, 0% 100%)",
            paddingTop: theme.spacing(0),
            marginTop: '-42rem',
            width: "100vw",
            height: '63%',
            left: 0,
            right: 0,
          }}
        >
          <Box sx={{
            textAlign: "center",
            px: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <div style={{
              width: 500,
              display: 'flex',
              flexDirection: 'column',
              marginTop: '110px'
            }}>
              <Typography variant="h6" sx={{
                fontFamily: 'Raleway', fontSize: '24px',
                fontWeight: 700, color: "#212121"
              }}>
                ANÁLISIS
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: generalContent.primary_color,
                  fontFamily: 'Raleway'
                }}
              >
                {generalContent.reportName}
              </Typography>
              <Typography variant="body2" sx={{
                fontFamily: 'Montserrat', fontSize: '14px',
                fontWeight: 400, color: "#616161"
              }}>
                {formatDate(scheme.reportUpdateDate)}
              </Typography>
              <Typography variant="body2" sx={{
                fontFamily: 'Raleway', fontSize: '24px',
                fontWeight: 700, color: "#424242"
              }}>
                {organization.name}
              </Typography>
              <div style={{marginTop: '16px'}}>
                <img
                  src={powered}
                  alt="FGV Logo"
                  style={{
                    width: 145,
                    height: 16,
                    backgroundColor: 'white',
                  }}
                />
              </div>
              <div>
                <a href='https://www.crowdvoice.ai/info' target='_blank'
                   style={{textDecoration: 'none'}}>
                  <Typography variant="body2" sx={{
                    fontFamily: 'Montserrat', fontSize: '10px',
                    fontWeight: 300, color: "#616161"
                  }}>
                    www.crowdvoice.ai/info
                  </Typography>
                </a>
              </div>
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            display: 'flex',
            flexDirection: 'column',
            gap: '12px', // Espacio entre los botones
            alignItems: 'flex-end', // Asegura que los botones estén alineados a la derecha
          }}
        >
          {isShowOptions && (
            <>
              <IconButton
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: '12px',
                  backgroundColor: '#F5F5F5',
                  alignSelf: 'center',
                  '&:hover': {
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                    transition: 'box-shadow ease-in-out',
                    backgroundColor: '#F5F5F5',
                  }
                }}
                onClick={() => exportPDF(schemeModified, schemeColor, scheme.jsonSchemePDF, scheme.jsonSchemePDF.general.description, organization, scheme.jsonSchemePDF.general.primary_color, scheme.jsonSchemePDF.general.secondary_color, palette, convertSvgToPngBase64, scheme.jsonSchemePDF.general.image)}
              >
                <MuiPDFIcon color='#424242'/>
              </IconButton>
              <IconButton
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: '12px',
                  backgroundColor: '#F5F5F5',
                  alignSelf: 'center',
                  '&:hover': {
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                    transition: 'box-shadow ease-in-out',
                    backgroundColor: '#F5F5F5',
                  }
                }}
                onClick={handleCopyUrl}
              >
                <LinkIcon sx={{color: '#424242'}}/>
                {isCopied && <CheckCircle sx={{
                  color: generalContent.primary_color,
                  position: 'absolute',
                  top: -3,
                  right: -3,
                  width: 16,
                  height: 16
                }}/>}
              </IconButton>
            </>
          )}
          <IconButton
            sx={{
              width: 56,
              height: 56,
              borderRadius: '16px',
              backgroundColor: '#F5F5F5',
              '&:hover': {
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                transition: 'box-shadow ease-in-out',
                backgroundColor: '#F5F5F5',
              }
            }}
            onClick={() => setIsShowOptions(!isShowOptions)}
          >
            <MoreVert sx={{color: 'black'}}/>
          </IconButton>
          {showGoHome &&
            <IconButton
              sx={{
                width: 56,
                height: 56,
                borderRadius: '16px',
                backgroundColor: generalContent.primary_color,
                '&:hover': {
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Sombra suave
                  transition: 'box-shadow ease-in-out', // Suaviza la transición
                  backgroundColor: generalContent.primary_color,
                },
              }}
              onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
            >
              <ArrowUpward sx={{color: 'white'}}/>
            </IconButton>}
        </Box>
      </section>

      {/*2. About Company*/}
      <section id='sectionAboutCompany'
               style={{backgroundColor: "white", height: 'auto'}}>
        <Grid
          container
          spacing={6}
          display='flex'
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Grid item style={{width: '551px', height: 'auto'}}>
            <Box>
              <Typography variant="h4" style={{
                color: '#212121', fontFamily: 'Raleway',
                fontWeight: 400, fontSize: '35px', marginBottom: '24px'
              }}>
                About <span
                style={{color: generalContent.primary_color}}>Company</span>
              </Typography>
              <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                <Typography variant="body1" style={{
                  color: '#616161', fontFamily: 'Montserrat',
                  fontWeight: 400, fontSize: '16px',
                  display: 'inline'
                }}>
                  {generalContent.description}
                </Typography>
              </Box>
              <div style={{marginTop: '38px'}}>
                <img
                  src={generalContent.image}
                  alt="img6"
                  style={{
                    width: '47%',
                    height: '10%',
                    clipPath: "circle(80.9% at 8% 91%)",
                  }}
                />
                <img
                  src={ellipse}
                  alt="img7"
                  style={{width: '47%', height: '10%', marginLeft: '-9rem'}}
                />
              </div>
            </Box>
          </Grid>

          <Grid item style={{width: '551px', height: 'auto'}}>
            <Box>
              <Typography variant="h4" style={{
                color: '#212121', fontFamily: 'raleway',
                fontWeight: 400, fontSize: '35px', marginBottom: '24px'
              }}>
                Content <span
                style={{color: generalContent.primary_color}}>List</span>
              </Typography>
              <Box sx={{
                display: 'flex', alignItems: 'flex-start', padding: '24px',
                borderRadius: '24px', gsp: '10',
                backgroundColor: generalContent.primary_color
              }}>
                <List>
                  {sections.map((section) => (
                    <ListItem key={section.id} sx={{padding: "4px 0"}}>
                      {!section.root && (<ListItemIcon>
                        <CircleIcon sx={{
                          color: 'white',
                          height: '5px',
                          width: '5px',
                          marginLeft: '10px'
                        }}/>
                      </ListItemIcon>)}
                      <Link component="button"
                            onMouseMove={() => eventAux(section.id, section.index)}
                            onClick={() => handleGoToSection(section.id)}
                            sx={{textDecoration: "none", color: "white"}}>
                        <Typography variant="subtitle1" style={{
                          color: 'white',
                          textAlign: 'left',
                          fontFamily: section.root ? 'Raleway' : 'Montserrat',
                          fontWeight: section.root ? 600 : 400,
                          fontSize: section.root ? '16px' : '14px',
                          marginLeft: section.root ? '0px' : '-2rem',
                        }}>
                          {section.title}
                        </Typography>
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Grid>
          <Divider style={{
            borderColor: '#E0E0E0',
            width: '80%',
            margin: ' 50px 0px 50px 40px'
          }}/>
        </Grid>
      </section>

      {/*3. Executive Summary*/}
      <section id='sectionExecutiveSummary' style={{height: 'auto'}}>
        <Grid item xs={6} style={{textAlign: 'center', marginLeft: '-4rem'}}>
          <Typography variant="h4" style={{
            color: '#212121', fontFamily: 'raleway',
            fontWeight: 400, fontSize: '35px', marginBottom: '24px'
          }}>
            1.Executive <span
            style={{color: generalContent.primary_color}}>Summary</span>
          </Typography>
        </Grid>
        <Grid
          container
          spacing={2}
          display='flex'
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Grid item style={{width: '551px', height: '100%',}}>
            {executiveSummary.sections.slice(0, 2).map((item, index) => (
              <>
                <Box id={`executiveSummary-${index}`} sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between'
                }}>
                  <Typography style={{
                    color: '#424242', fontFamily: 'Raleway',
                    fontWeight: 700, fontSize: '20px'
                  }}>
                    1.{index + 1}.{item.sectionName}
                  </Typography>

                  <IconButton edge="end" size="small" aria-label="edit"
                              sx={iconButtonStyle}>
                    {activeEditor && matchEditorElement === index ? (
                      <CloseIcon style={{
                        ...iconStyle,
                        color: generalContent.primary_color
                      }} onClick={handleCloseEditor}/>
                    ) : (
                      <EditIcon style={iconStyle}
                                onClick={() => handleToggleEditor(index)}/>
                    )}
                  </IconButton>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                  {activeEditor && matchEditorElement === index ? (
                    <MuiEditor elevation={0}>
                      <Box sx={{height: 'auto', width: '100%'}}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={item.sectionContent}
                          config={{
                            removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                            toolbar: [
                              'heading', '|', 'bold', 'italic', 'underline', '|',
                              'bulletedList', 'numberedList', '|',
                              'undo', 'redo'
                            ],
                          }}
                          onChange={(e, editor) => handleEditorChange(e, editor, 'executive_summary', index)}
                        />
                      </Box>
                      <Button
                        startIcon={(<CheckIcon/>)}
                        variant='outlined'
                        sx={{
                          marginTop: '10px',
                          borderRadius: '20px',
                          height: '37px',
                          color: generalContent.primary_color,
                          borderColor: generalContent.primary_color,
                          '&:hover': {
                            borderColor: generalContent.primary_color,
                            backgroundColor: lighten(generalContent.primary_color, 0.8),
                          }
                        }}
                        onClick={() => handleDispatch('executive_summary', index)}
                      >
                        aceptar
                      </Button>
                    </MuiEditor>
                  ) : (
                    <Typography variant="body1" style={{
                      color: '#616161', fontFamily: 'Montserrat',
                      fontWeight: 400, fontSize: '16px',
                      display: 'inline'
                    }}
                                dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                    </Typography>
                  )}
                </Box>
              </>
            ))}
          </Grid>

          <Grid item style={{
            width: '551px', height: '100%',
            borderRadius: '24px', paddingLeft: '24px', paddingRight: '24px'
          }}>
            {executiveSummary.sections.slice(2, 4).map((item, index) => (
              <>
                <Box id={`executiveSummary-${index + 2}`} sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between'
                }}>
                  <Typography style={{
                    color: '#424242', fontFamily: 'Raleway',
                    fontWeight: 700, fontSize: '20px'
                  }}>
                    1.{index + 3}.{item.sectionName}
                  </Typography>
                  <IconButton edge="end" size="small" aria-label="edit"
                              sx={iconButtonStyle}>
                    {activeEditor && matchEditorElement === index + 2 ? (
                      <CloseIcon style={{
                        ...iconStyle,
                        color: generalContent.primary_color
                      }} onClick={handleCloseEditor}/>
                    ) : (
                      <EditIcon style={iconStyle}
                                onClick={() => handleToggleEditor(index + 2)}/>
                    )}
                  </IconButton>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                  {activeEditor && matchEditorElement === index + 2 ? (
                    <MuiEditor elevation={0}>
                      <Box sx={{minHeight: '300px', width: '100%'}}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={item.sectionContent}
                          config={{
                            removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                            toolbar: [
                              'heading', '|', 'bold', 'italic', 'underline', '|',
                              'bulletedList', 'numberedList', '|',
                              'undo', 'redo'
                            ],
                          }}
                          onChange={(e, editor) => handleEditorChange(e, editor, 'executive_summary', index + 2)}
                        />
                      </Box>
                      <Button
                        startIcon={(<CheckIcon/>)}
                        variant='outlined'
                        sx={{
                          marginTop: '10px',
                          borderRadius: '20px',
                          height: '37px',
                          color: generalContent.primary_color,
                          borderColor: generalContent.primary_color,
                          '&:hover': {
                            borderColor: generalContent.primary_color,
                            backgroundColor: lighten(generalContent.primary_color, 0.8),
                          }
                        }}
                        onClick={() => handleDispatch('executive_summary', index + 2)}
                      >
                        aceptar
                      </Button>
                    </MuiEditor>
                  ) : (
                    <Typography variant="body1" style={{
                      color: '#616161', fontFamily: 'Montserrat',
                      fontWeight: 400, fontSize: '16px',
                      display: 'inline'
                    }}
                                dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                    </Typography>
                  )}
                </Box>
              </>
            ))}
          </Grid>
          <Divider style={{
            borderColor: '#E0E0E0',
            width: '82%',
            margin: ' 50px 0px 0px 40px'
          }}/>
        </Grid>
      </section>

      {/*4. Grafico de pastel*/}
      <section id='sectionGraphPie' style={{height: 'auto', marginTop: '40px'}}>
        <Grid item xs={6} style={{textAlign: 'center', marginLeft: '-4rem'}}>
          <Typography variant="h4" style={{
            color: '#212121', fontFamily: 'raleway',
            fontWeight: 400, fontSize: '35px', marginBottom: '24px'
          }}>
            2.Relevant <span
            style={{color: generalContent.primary_color}}>Segments</span>
          </Typography>
        </Grid>
        <Grid
          container
          spacing={2}
          display='flex'
          direction='column'
          justifyContent="space-evenly"
          alignItems="center"
        >
          {segmentsAnalysis.sections.map((item, index) => (
            <>
              {index % 2 === 0 ? (
                <Grid item style={{
                  width: '1275px',
                  height: '100%',
                  padding: '24px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: 'white'
                }}
                      id={getIdSeccion('sectionGraphPie', item.sectionName)}
                >
                  <div style={{width: '50%'}}>
                    <Box sx={{
                      display: 'flex', alignItems: 'flex-start',
                      justifyContent: 'space-between'
                    }}>
                      <Typography style={{
                        color: '#424242', fontFamily: 'Raleway',
                        fontWeight: 700, fontSize: '20px'
                      }}>
                        2.{index + 1}.{item.sectionName}
                      </Typography>
                      <IconButton edge="end" size="small" aria-label="edit"
                                  sx={iconButtonStyle}>
                        {activeEditor && matchEditorElement === index ? (
                          <CloseIcon style={{
                            ...iconStyle,
                            color: generalContent.primary_color
                          }} onClick={handleCloseEditor}/>
                        ) : (
                          <EditIcon style={iconStyle}
                                    onClick={() => handleToggleEditor(index)}/>
                        )}
                      </IconButton>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                      {activeEditor && matchEditorElement === index ? (
                        <MuiEditor elevation={0}>
                          <Box sx={{height: 'auto', width: '100%'}}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.sectionContent}
                              config={{
                                removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                                toolbar: [
                                  'heading', '|', 'bold', 'italic', 'underline', '|',
                                  'bulletedList', 'numberedList', '|',
                                  'undo', 'redo'
                                ],
                              }}
                              onChange={(e, editor) => handleEditorChange(e, editor, 'segments_analysis', index)}
                            />
                          </Box>
                          <Button
                            startIcon={(<CheckIcon/>)}
                            variant='outlined'
                            sx={{
                              marginTop: '10px',
                              borderRadius: '20px',
                              height: '37px',
                              color: generalContent.primary_color,
                              borderColor: generalContent.primary_color,
                              '&:hover': {
                                borderColor: generalContent.primary_color,
                                backgroundColor: lighten(generalContent.primary_color, 0.8),
                              }
                            }}
                            onClick={() => handleDispatch('segments_analysis', index)}
                          >
                            aceptar
                          </Button>
                        </MuiEditor>
                      ) : (
                        <Typography variant="body1" style={{
                          color: '#616161', fontFamily: 'Montserrat',
                          fontWeight: 400, fontSize: '16px',
                          display: 'inline'
                        }}
                                    dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                        </Typography>
                      )}
                    </Box>
                  </div>
                  <PieChart
                    isInteractive={true}
                    width={422}
                    height={220}
                    top={20}
                    left={-100}
                    data={methodAux(item.additionalData.categories)}
                    translateX={-150}
                  />
                </Grid>
              ) : (
                <Grid item style={{
                  width: '1275px',
                  height: '100%',
                  padding: '24px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: '#FAFAFA',
                  borderTopRightRadius: '24px',
                  borderBottomRightRadius: '24px'
                }}
                      id={getIdSeccion('sectionGraphPie', item.sectionName)}
                >
                  <PieChart
                    isInteractive={true}
                    width={422}
                    height={220}
                    top={20}
                    left={0}
                    data={methodAux(item.additionalData.categories)}
                    translateX={-85}
                  />
                  <div style={{width: '50%'}}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between'
                    }}>
                      <Typography style={{
                        color: '#424242', fontFamily: 'Raleway',
                        fontWeight: 700, fontSize: '20px'
                      }}>
                        2.{index + 1}.{item.sectionName}
                      </Typography>
                      <IconButton edge="end" size="small" aria-label="edit"
                                  sx={iconButtonStyle}>
                        {activeEditor && matchEditorElement === index ? (
                          <CloseIcon style={{
                            ...iconStyle,
                            color: generalContent.primary_color
                          }} onClick={handleCloseEditor}/>
                        ) : (
                          <EditIcon style={iconStyle}
                                    onClick={() => handleToggleEditor(index)}/>
                        )}
                      </IconButton>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                      {activeEditor && matchEditorElement === index ? (
                        <MuiEditor elevation={0}>
                          <Box sx={{height: 'auto', width: '100%'}}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.sectionContent}
                              config={{
                                removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                                toolbar: [
                                  'heading', '|', 'bold', 'italic', 'underline', '|',
                                  'bulletedList', 'numberedList', '|',
                                  'undo', 'redo'
                                ],
                              }}
                              onChange={(e, editor) => handleEditorChange(e, editor, 'segments_analysis', index)}
                            />
                          </Box>
                          <Button
                            startIcon={(<CheckIcon/>)}
                            variant='outlined'
                            sx={{
                              marginTop: '10px',
                              borderRadius: '20px',
                              height: '37px',
                              color: generalContent.primary_color,
                              borderColor: generalContent.primary_color,
                              '&:hover': {
                                borderColor: generalContent.primary_color,
                                backgroundColor: lighten(generalContent.primary_color, 0.8),
                              }
                            }}
                            onClick={() => handleDispatch('segments_analysis', index)}
                          >
                            aceptar
                          </Button>
                        </MuiEditor>
                      ) : (
                        <Typography variant="body1" style={{
                          color: '#616161', fontFamily: 'Montserrat',
                          fontWeight: 400, fontSize: '16px',
                          display: 'inline'
                        }}
                                    dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                        </Typography>
                      )}
                    </Box>
                  </div>
                </Grid>
              )}
            </>
          ))}
          <Divider style={{
            borderColor: '#E0E0E0',
            width: '86%',
            margin: ' 50px 0px 0px 40px'
          }}/>
        </Grid>
      </section>

      {/*5. Grafico de barras horizontal*/}
      <section id='sectionGraphBarH'
               style={{height: 'auto', marginTop: '50px'}}>
        <Grid container spacing={4}
              justifyContent="center"
              alignItems="center"
              direction='column'
              style={{borderRadius: 0}}>
          <Typography variant="h4" style={{
            color: '#212121', fontFamily: 'raleway',
            fontWeight: 400, fontSize: '35px', marginBottom: '24px'
          }}>
            3. Responses<span
            style={{color: generalContent.primary_color}}> Analysis</span>
          </Typography>
          <div
            style={{
              position: isFixed ? 'fixed' : 'sticky',
              top: 0,
              zIndex: 1000,
              backgroundColor: 'white',
              borderRadius: '24px',
              transition: 'all 0.3s ease',
              padding: '0px 48px',
              boxShadow: isFixed ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
              marginTop: isFixed ? '16px' : '0px',
            }}
          >
            <Tabs
              sx={{
                '& .Mui-selected': {
                  fontSize: 14,
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                },
              }}
              value={activeTab}
              onChange={handleChange}
              orientation="horizontal"
              indicatorColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: lighten(generalContent.primary_color, 0.1),
                  height: 3,
                  borderRadius: '10px 10px 0 0',
                  transition: 'width 0.3s',
                },
              }}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  style={{
                    color:
                      activeTab === tab.id
                        ? lighten(generalContent.primary_color, 0.2)
                        : '#424242',
                    fontSize: 14,
                    fontFamily: 'Montserrat',
                    fontWeight: 500,
                  }}
                  label={tab.value}
                  value={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                />
              ))}
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={12}>
          {tabContent(openQuestionAnalysis)}
        </Grid>
      </section>

      {/*10. Conclusiones*/}
      <section id='sectionConclutions'
               style={{
                 height: 'auto',
                 backgroundColor: generalContent.primary_color
               }}>
        <Grid
          container
          spacing={2}
          display='flex'
          justifyContent="space-evenly"
          alignItems="center"
          sx={{marginTop: '50px', padding: '32px 10px'}}
        >
          <Grid item alignSelf='flex-start'
                style={{width: '651px', height: 'auto'}}>
            <Box sx={{
              display: 'flex', alignItems: 'center',
              justifyContent: 'space-between', marginTop: '24px'
            }}>
              <Typography variant="h4" style={{
                color: 'white', fontFamily: 'raleway',
                fontWeight: 400, fontSize: '35px', marginBottom: '24px'
              }}>
                4 Conclusions
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column'
            }}>
              {conclusions.sections[0].sectionContent.map((item, index) => (
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start'
                }}>
                  {activeEditor && matchEditorElement === index ? (
                    <MuiEditor elevation={0}>
                      <Box sx={{height: 'auto', width: '100%'}}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={`${item.conclusionTitle}:${item.conclusionContent}`}
                          config={{
                            removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                            toolbar: [
                              'heading', '|', 'bold', 'italic', 'underline', '|',
                              'bulletedList', 'numberedList', '|',
                              'undo', 'redo'
                            ],
                          }}
                          onChange={(e, editor) => handleEditorChange(e, editor, 'conclusions', index)}
                        />
                      </Box>
                      <Button
                        startIcon={(<CheckIcon/>)}
                        variant='outlined'
                        sx={{
                          marginTop: '10px',
                          borderRadius: '20px',
                          height: '37px',
                          color: generalContent.primary_color,
                          borderColor: generalContent.primary_color,
                          '&:hover': {
                            borderColor: generalContent.primary_color,
                            backgroundColor: lighten(generalContent.primary_color, 0.8),
                          }
                        }}
                        onClick={() => handleDispatch('conclusions', index)}
                      >
                        aceptar
                      </Button>
                    </MuiEditor>
                  ) : (
                    <p style={{
                      color: 'white', fontFamily: 'Montserrat',
                      fontWeight: 400, fontSize: '16px'
                    }}>
                      <strong>{item.conclusionTitle}: </strong>
                      {item.conclusionContent}
                    </p>
                  )}
                  <IconButton edge="end" size="small" aria-label="edit"
                              sx={{...iconButtonStyle, marginTop: '15px'}}>
                    {activeEditor && matchEditorElement === index ? (
                      <CloseIcon style={{...iconStyle, color: 'white'}}
                                 onClick={handleCloseEditor}/>
                    ) : (
                      <EditIcon style={{...iconStyle, color: 'white'}}
                                onClick={() => handleToggleEditor(index)}/>
                    )}
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item alignSelf='flex-start'>
            <Box
              sx={{
                width: "100%",
                maxWidth: 600,
                borderRadius: "50%",
                overflow: "hidden",
                backgroundColor: generalContent.primary_color,
              }}
            >
              <Grid container spacing={0.5} sx={{justifyContent: "center"}}>
                {imagesConclusion.map((image, index) => (
                  <Grid item xs={6} key={index}>
                    <Box
                      component="img"
                      src={image}
                      alt={`img-${index}`}
                      sx={{
                        width: '99%',
                        height: 460,
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </section>

      {/*11. Galeria*/}
      <section id='sectionGallery' style={{height: 'auto'}}>
        <Grid
          container
          spacing={2}
          display='flex'
          justifyContent="space-evenly"
          alignItems="center"
          sx={{marginTop: '50px'}}
        >
          <Grid item style={{width: '85%', height: 'auto'}}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
              <Typography variant="h4" style={{
                color: '#424242', fontFamily: 'Montserrat',
                fontWeight: 400, fontSize: '35px'
              }}>
                5 Gallery
              </Typography>
              {Object.keys(openQuestionAnalysis).map((item, index) => (
                <Box sx={{marginTop: '32px'}}>
                  <Typography variant="h4" style={{
                    color: '#424242', fontFamily: 'Montserrat',
                    fontWeight: 500, fontSize: '16px'
                  }}>
                    Question {index + 1}
                  </Typography>
                  <Typography variant="h4" style={{
                    color: '#212121', fontFamily: 'raleway',
                    fontWeight: 700, fontSize: '20px'
                  }}>
                    {item}
                  </Typography>
                  {matchQuestion(item)}
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default ReportPDF;
