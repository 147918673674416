import {normalizeString} from "../../../../../../utils/text";
import * as React from "react";
import color from "color";

const CustomTick = ({tick, maxLineLength, topics, selectedNodeData,
                      selectedTopic, layout='horizontal'}) => {

  const isSelected = selectedNodeData?.indexValue === tick.value

  const renderIcon = (base64Icon, x, y, iconColor = '#212121', backgroundColor = '#EEEEEE', isSelected = false, selectedNodeData = null) => {

    const decodeBase64Icon = (base64Icon) => {
      const svgString = atob(base64Icon);
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
      const paths = svgDoc.querySelectorAll('path');
      const svgElement = svgDoc.querySelector('svg');
      const viewBox = svgElement ? svgElement.getAttribute('viewBox') : '0 0 20 20';

      let scale = 1;
      let viewBoxDims = [0, 0, 20, 20];
      if (viewBox) {
        viewBoxDims = viewBox.split(' ').map(Number);
        const [minX, minY, width, height] = viewBoxDims;
        scale = Math.min(20 / width, 20 / height);
      }

      return {
        paths: Array.from(paths).map(path => ({
          d: path.getAttribute('d'),
          scale: scale
        })),
        viewBoxDims
      };
    };

    const scaleIcon = (scale, viewBoxDims) => {
      const [minX, minY, width, height] = viewBoxDims;
      const iconSize = 18;
      const rectSize = 32;
      const translateX = x + (rectSize - width * scale) / 2 - minX * scale-(iconSize/2);
      const translateY = y + (rectSize - height * scale) / 2 - minY * scale-(iconSize/2);

      const transformStyle = {
        transform: `translate(${translateX - (layout === 'vertical' ? 25 : 0)}px,
        ${translateY + (layout === 'vertical' ? 30 : 0)}px) scale(${scale})`,
      };

      return transformStyle
    };

    const {paths: iconPaths, viewBoxDims} = decodeBase64Icon(base64Icon);


    const backgroundColorStyle = {
      fill: backgroundColor,
      stroke: backgroundColor,
      strokeWidth: 0.5,
      opacity: 1
    };
    let effectiveIconColor=iconColor;
    if (isSelected) {
      backgroundColorStyle.stroke = color(backgroundColor).darken(0.2).hex();
      backgroundColorStyle.strokeWidth = 1.5;
      effectiveIconColor=color(iconColor).darken(0.2).hex();
    } else if (selectedNodeData && !isSelected&& !selectedNodeData?.data?.isParent) {
      backgroundColorStyle.opacity = 0.4;
      effectiveIconColor=color(iconColor).lighten(0.2).hex();
    }

    return (
      <>
        <rect
          x={layout === 'vertical' ? x - 8 - 25 :  x - 8}
          y={layout === 'vertical' ? y - 10 + 30 :  y - 10}
          width={32}
          height={32}
          {...backgroundColorStyle}
          rx={4}
          ry={4}
        />
        {iconPaths.map((iconPath, index) => (
          <path
            key={index}
            d={iconPath.d}
            style={{...scaleIcon(iconPath.scale, viewBoxDims), fill: effectiveIconColor}}
          />
        ))}
      </>
    );
  };



  const wrapText = (text, maxLineLength) => {
    const words = text.split(' ');
    let lines = [];
    let currentLine = '';

    words.forEach(word => {
      if ((currentLine + word).length <= maxLineLength) {
        currentLine += `${word} `;
      } else {
        lines.push(currentLine.trim());
        currentLine = `${word} `;
      }
    });

    if (currentLine.length > 0) {
      lines.push(currentLine.trim());
    }

    return lines;
  };

  let tickIcon = topics.find((topic) => normalizeString(topic.topic) === normalizeString(tick.value))?.icon || null;
  if (!tickIcon) {
    topics.forEach((topic) => {
      const subtopic = topic.subtopics?.find((subtopic) => normalizeString(subtopic.topic) === normalizeString(tick.value));
      if(subtopic) {
        tickIcon = subtopic.icon
      }
    })
  }
  const lines = wrapText(tick.value, maxLineLength);

  const textStyle = {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontStyle: 'normal',
    color:'#424242'
  };

  if (isSelected) {
    textStyle.fontWeight = '600';
  } else if (selectedNodeData && !isSelected&&!selectedNodeData?.data?.isParent) {
    textStyle.opacity = 0.6
  }

  const getTransform = () => {
    const offset = lines.length > 1 ? 16 * ((lines.length - 1) / 2): 0;
    return `translate(${tick.x - (layout === 'vertical' ? 0 : 55)}, ${tick.y - offset})`;
  };

const getOffsetYToVertical = () =>  {
  const offsetY = lines.length === 1 ? 60 : lines.length === 2 ? 68 : 75;
  return offsetY;
}
  return (
    <g transform={getTransform()} width={tick.width}>
      {lines.map((line, index) => (
        <text
          key={`tick-${tick.tickIndex}-line-${index}`}
          textAnchor={layout === 'vertical' ? 'middle' : 'end'}
          dominantBaseline="middle"
          {...textStyle}
          x={ layout === 'vertical' ? tick.width / 2 : 0}
          y={ layout === 'vertical' ? (index * 16) + getOffsetYToVertical() : index * 16}
        >
          {line}
        </text>
      ))}
      {!!tickIcon && (
        renderIcon(tickIcon, maxLineLength, ((lines.length / 2) * 14) - 15, '#616161','#EEEEEE', isSelected, selectedNodeData)
      )}
    </g>
  );
};

export default CustomTick;
