import {
    Autocomplete,
    styled,
  } from "@mui/material";
import {darken} from "@mui/material/styles";

export const MuiDraggableAutocomplete = styled(Autocomplete)(({theme}) => {
    let closeIconColor = darken(theme.palette.primary.main, 0.5);
    return {
        '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#325D6C',
            borderRadius: '4px 4px 0 0',
        },
        '&:hover fieldset': {
            borderColor: '#143440',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#254957',
        },
    },
    '& .MuiInputBase-root': {
        width: '100%',
        borderRadius: '4px 4px 0 0',
    },
    '& .MuiChip-deleteIcon': {
        color: closeIconColor,
        backgroundColor: 'transparent',
        width: '18px',
        height: '18px',
    },
  }
});  