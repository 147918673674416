import * as jspdf from "jspdf";
// import axios from "axios";
import axiosGeneric from "../../../../../api/axios/axiosGenericInstance";
import {getContrastTonality} from "../../../../../utils/colorPaletteUtils";
import PieChart from "../analysis/tree/PieChart";
import html2canvas from "html2canvas";
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

export const generateFrontPage = async (doc, scheme, organization, position, color, logoOrg, logoProsperia, palette) => {
  const imageWidth = 281;
  const imageHeight = 192;
  const padding = 4;
  const images = getRandomImages(scheme, 10);
  const palletteColors = palette.map(color => {
    const match = color.match(/\d+/g);
    return match ? match.map(Number) : [];
  });

  let xPosition = padding;
  let yPosition = padding;
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.getWidth();
  for (let i = 0; i < 10; i++) {
    if (i < 3) {
      xPosition = 0;
      yPosition = i * (imageHeight + padding);
    } else if (i >= 3 && i < 7) {
      xPosition = imageWidth + padding;
      yPosition = (pageHeight - (4 * imageHeight + 3 * padding)) / 2 + (i - 3) * (imageHeight + padding);
    } else {
      xPosition = imageWidth * 2 + padding * 2;
      yPosition = (i === 7) ? 0 : (i - 7) * (imageHeight + padding);
    }
    try {
      if (images.length > 0) {
        const base64Image = await convertImageUrlToBase64(images[i]);
        doc.addImage(base64Image, 'WEBP', xPosition, yPosition, imageWidth, imageHeight);
      } else {
        doc.setFillColor(...palletteColors[i])
        doc.rect(xPosition, yPosition, imageWidth, imageHeight, 'F');
      }

    } catch (e) {
      console.error('Error adding image', e);
    }
  }
  let y = position.y + 20
  doc.setFillColor(...color);
  doc.circle(pageWidth / 2, 210, 101, 'F')
  try {
    doc.addImage('https://crowd-view.s3.us-east-1.amazonaws.com/escenarie_center.png', 'PNG', 0, 209, 842, 386.5);
  } catch (e) {
    console.error('Error adding image', e);
  }
  doc.setFillColor(255, 255, 255);
  doc.circle(pageWidth / 2, 210, 83, 'F')
  try {
    doc.addImage(`${logoOrg}`, 'PNG', 365, 160, 112, 112);
  } catch (e) {
    console.error('Error adding image', e);
  }
  doc.setFontSize(getFontSize(24));
  doc.setFont('Raleway', 'bold');
  doc.setTextColor(33, 33, 33);
  try {
    doc.addImage('https://crowd-view.s3.us-east-1.amazonaws.com/gray_front.png', 'PNG', 328, pageHeight / 2 - 56, 187, 65);
  } catch (e) {
    console.error('Error adding image', e);
  }
  doc.text('ANÁLYSIS', position.x, y, {align: 'center'});
  doc.setFontSize(getFontSize(32));
  y += getFontSize(32) * 0.35;
  doc.setFont('Raleway', 'regular');
  doc.setTextColor(...color);
  y += getFontSize(32) * 0.35 + 12;
  doc.setFontSize(getFontSize(30));
  doc.text(`${scheme.name}`, position.x, y, {maxWidth: 500, align: 'center'});
  doc.setFontSize(getFontSize(13));
  y += 62
  doc.setFont('Montserrat', 'regular');
  doc.setTextColor(97, 97, 97);
  doc.text(`${scheme.createdAt.split(' ')[0]}`, position.x, y, {align: 'center'});
  doc.setFontSize(getFontSize(16));
  y += 28
  doc.setTextColor(66, 66, 66);
  doc.setFont('Raleway', 'regular');
  doc.text(`${organization.name}`, position.x, y, {align: 'center'});
  doc.addImage(logoProsperia, 'PNG', 373, doc.internal.pageSize.getHeight() - 62, 95, 12);
  doc.setTextColor(97, 97, 97);
  doc.setFont('Montserrat', 'regular');
  doc.setFontSize(getFontSize(7))
  doc.text('www.crowdvoice.ai/info', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 38, {align: 'center'});
  const url = "https://www.crowdvoice.ai/info";
  const textWidth = doc.getTextWidth('www.crowdvoice.ai/info');
  doc.link(doc.internal.pageSize.getWidth() / 2 - 40, doc.internal.pageSize.getHeight() - 33, textWidth, 7, {url: url});
}
export const generateTableOfContents = (doc, color, logoOrg, gridOrg, description, organization) => {
  addPage(doc, false);
  addText(doc, 'About', [33, 33, 33], 'Raleway', 'bold', 'left', 0, 20, 30, 50);
  addText(doc, `${organization.name}`, color, 'Raleway', 'bold', 'left', 0, 20, 95, 50);
  addText(doc, description, [33, 33, 33], 'Montserrat', 'regular', 'left', 341, 10, 30, 84)
  try {
    doc.addImage(logoOrg, 'PNG', 18, 470, 123, 99);
    doc.addImage(gridOrg, 'PNG', 0, 393, 220, 202)
  } catch (e) {
    console.error('Error adding image', e);
  }
  addText(doc, 'Content', [33, 33, 33], 'Raleway', 'bold', 'left', 0, 20, 452, 50);
  addText(doc, 'List', color, 'Raleway', 'bold', 'left', 0, 20, 536, 50);
  addRoundedRectangle(doc, color, 421, 74, 421, 496, 24, 'left')
}
export const addPage = (doc, pageNumber = true) => {
  const number = doc.getNumberOfPages() + 1
  doc.addPage();
  if (pageNumber) {
    addText(doc, number, [97, 97, 97], 'Raleway', 'bold', 'right', 0, 10, doc.internal.pageSize.getWidth() - 30, doc.internal.pageSize.getHeight() - 18);
  }
}
export const addExecutiveSummary = (doc, contentList, color, colorS, data) => {
  addPage(doc);
  let y = 50;
  addText(doc, '1. Executive', [33, 33, 33], 'Raleway', 'bold', 'left', 0, 20, 30, y);
  addText(doc, ' Summary', color, 'Raleway', 'bold', 'left', 0, 20, 140, y);
  y += 33;
  contentList.push({
    'name': 'Executive Summary',
    'page': doc.getNumberOfPages(),
    'items': data.map((summary) => ({name: summary.sectionName, page: ''}))
  });
  addRoundedRectangle(doc, color, 421, 0, 421, 595, 30, 'left')
  data.forEach((summary, index) => {
    if (index === 0) {
      addText(doc, `1.${index + 1}. ${summary.sectionName}`, [66, 66, 66], 'Raleway', 'bold', 'left', 0, 12, 30, y);
      y += 17;
      y = addTextFromHtml(doc, summary.sectionContent, 30, y, 379, 'Montserrat', 10, [97, 97, 97], color, true);
    } else if (index === 1) {
      addText(doc, `1.${index + 1}. ${summary.sectionName}`, [66, 66, 66], 'Raleway', 'bold', 'left', 0, 12, 30, y);
      y += 17;
      addTextFromHtml(doc, summary.sectionContent, 30, y, 379, 'Montserrat', 10, [97, 97, 97], color);
      y = 83;
    } else if (index === 2) {
      addText(doc, `1.${index + 1}. ${summary.sectionName}`, [255, 255, 255], 'Raleway', 'bold', 'left', 0, 12, 433, y);
      y += 17;
      y = addTextFromHtml(doc, summary.sectionContent, 439, y, 371, 'Montserrat', 10, [255, 255, 255], [255, 255, 255]);
    } else {
      addText(doc, `1.${index + 1}. ${summary.sectionName}`, [255, 255, 255], 'Raleway', 'bold', 'left', 0, 12, 433, y);
      y += 17
      addTextFromHtml(doc, summary.sectionContent, 439, y, 371, 'Montserrat', 10, [255, 255, 255], [255, 255, 255], true);
    }
  })
}
export const addRelevantSegments = async (doc, scheme, contentList, color, data, palette, triangle) => {
  addPage(doc);
  addText(doc, '2. Relevant', [33, 33, 33], 'Raleway', 'bold', 'left', 0, 20, 30, 50);
  addText(doc, ' Segments', color, 'Raleway', 'bold', 'left', 0, 20, 140, 50);
  contentList.push({
    'name': 'Relevant Segments',
    'page': doc.getNumberOfPages(),
    'items': data.map((summary) => ({name: summary.sectionName, page: ''})),
  });
  doc.addImage(triangle, 'WEBP', 346, 563, 151, 32);
  const initX = 30;
  const initY = 86;
  let x = initX;
  let y = initY;
  const minHeight = 150;
  let graphY = y - 12;
  let segmentCounter = 0;

  async function generatePieChartAndCapture(doc, dataSegment, x, y, variable) {
    const styleEl = document.createElement('style');
    styleEl.innerHTML = `
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');
  `;
    document.head.appendChild(styleEl);
    try {
      // loads "Montserrat" at 10px or 12px, whichever you use
      await document.fonts.load('12px Montserrat');
      // or wait for *all* fonts in the document:
      // await document.fonts.ready;
    } catch (err) {
      console.warn('Font failed to load, continuing anyway', err);
    }
    const container = document.createElement('div');
    container.id = `temporary-chart-container-${variable}`;
    container.style.position = 'absolute';
    container.style.top = '-10000px';
    container.style.left = '-10000px';
    container.style.width = '420px';
    container.style.height = '150px';
    container.style.fontFamily = 'Montserrat';
    container.style.fontSize='14px';
    document.body.appendChild(container);
    const root=createRoot(container);
    root.render(<PieChart data={dataSegment}
                          width={420}
                          height={150}/>)
    await new Promise((resolve) => setTimeout(resolve, 1000));
    try {
      const canvas = await html2canvas(container, {useCORS: true, scale: 4});
      const imgData = canvas.toDataURL('image/png', 4);
      doc.addImage(imgData, 'PNG', 480, y, 420, minHeight);
    } catch (error) {
      console.error("Error al generar la imagen:", error);
    } finally {
      root.unmount();
      document.body.removeChild(container);
    }
  }

  for (const summary of data) {
    const index = data.indexOf(summary);
    let dataSegment = [];
    const variable = summary.additionalData.propName;
    const valueTotal = summary.additionalData.categories.reduce((sum, obj) => sum + (obj.occurrences || 0), 0);
    const sortedCategoires = [...summary.additionalData.categories].sort((a, b) => b.occurrences - a.occurrences);
    const topSeven = sortedCategoires.slice(0, 7);
    const others = sortedCategoires.slice(7);
    const otherOccurrences = others.reduce((sum, obj) => sum + (obj.occurrences || 0), 0);

    topSeven.forEach((segment, index) => {
      dataSegment.push({
        label: `${segment.name} (${segment.occurrences})`,
        value: Math.round(segment.occurrences * 100 / valueTotal, 1),
        color: palette[index % palette.length]
      });
    })
    if (others.length > 0) {
      dataSegment.push({
        label: `Other (${otherOccurrences})`,
        value: Math.round(otherOccurrences * 100 / valueTotal, 1),
        color: palette[7 % palette.length] // or however you want to pick the color
      });
    }
    if (segmentCounter >= 3 || y > 425) {
      addPage(doc);
      addText(doc, '2. Relevant', [33, 33, 33], 'Raleway', 'bold', 'left', 0, 20, 30, 50);
      addText(doc, ' Segments', color, 'Raleway', 'bold', 'left', 0, 20, 140, 50);
      doc.addImage(triangle, 'WEBP', 346, 563, 151, 32);
      x = initX;
      y = initY;
      graphY = y - 12;
      segmentCounter = 0;
    }
    addText(doc, `2.${index + 1}. ${summary.sectionName}`, [66, 66, 66], 'Raleway', 'bold', 'left', 349, 12, x, y);
    y += 18;
    let lastY = 0;
    lastY = addTextFromHtml(doc, summary.sectionContent, x, y, 349, 'Montserrat', 10, [97, 97, 97], [97, 97, 97]);
    y = lastY - y > minHeight ? lastY + 32 : graphY + minHeight + 32;
    await generatePieChartAndCapture(doc, dataSegment, 480, graphY, variable);
    graphY = y - 12;
    segmentCounter++;
  }
}
export const addBarGraph = (doc, scheme, data, x, y, width, height, palette, topicIndexed, direction = 'vertical') => {
  if (!data || !data.additionalData) return;

  const titleUpper = data.sectionName.toUpperCase();
  const spacing = 4; // Espaciado entre barras dentro de un grupo
  const groupSpacing = 13; // Espaciado entre grupos
  const topics = data.additionalData.length;
  const dataSorted = [...data.additionalData].sort((a, b) => b.representativity - a.representativity);
  let legend = [];

  if (direction === 'vertical') {
    // **Gráfico de barras vertical**
    const filteredData = dataSorted.filter((item) => topicIndexed.has(item.topic));
    const totalBars = filteredData.reduce((acc, topic) => acc + topic.collapsed_categories.length, 0);
    const totalGroupSpacing = groupSpacing * (topics - 1);
    const totalBarSpacing = spacing * (totalBars - topics);
    const barWidth = (width - totalGroupSpacing - totalBarSpacing) / totalBars;
    const maxPercentage = Math.ceil(
      Math.max(
        ...filteredData.flatMap((topic) =>
          topic.collapsed_categories.map((category) => category.percentage)
        )
      )
    );
    const yStep = 5; // Incrementos del eje Y
    const yTicks = Math.ceil(maxPercentage / yStep);
    const yUnitHeight = height / yTicks;

    let currentX = x + 31;
    let currentY = y + height;

    // Etiquetas del eje Y
    for (let i = 0; i <= yTicks; i++) {
      const value = i * yStep;
      const yPosition = currentY - i * yUnitHeight;
      doc.setFontSize(getFontSize(8));
      doc.setTextColor(0, 0, 0);
      doc.text(`${value}% -`, 55, yPosition + 3, {align: "right"});
    }

    // Dibujar barras
    let topicLabelX = 60;
    filteredData.forEach((topic, topicIndex) => {
      const groupWidth = topic.collapsed_categories.length * (barWidth + spacing);
      const maxWidth = groupWidth - spacing;
      topic.collapsed_categories.forEach((category, catIndex) => {
        const barHeight = (category.percentage / maxPercentage) * height;
        const barColor = palette[catIndex % palette.length];
        legend = addUniqueObject(legend, {
          name: category.name,
          color: barColor
        });

        doc.setFillColor(...barColor);
        if (category.percentage <= 0) {
          doc.rect(currentX, currentY, barWidth, 1, "F");
        } else {
          doc.roundedRect(currentX, currentY - barHeight, barWidth, barHeight, 4, 4, "F");
          doc.rect(currentX, currentY - 4, barWidth, 4, "F");
        }

        doc.setFontSize(getFontSize(6));
        doc.setTextColor(...barColor);
        doc.setFont('Montserrat', 'bold');
        doc.text(`${category.percentage.toFixed(1)}%`, currentX + barWidth / 2, currentY - barHeight - 5, {align: "center"});
        currentX += barWidth + spacing;
      });

      // Etiquetas del grupo
      const icon = findTopicIcon(topicIndexed, topic.topic);
      doc.setFont('Montserrat', 'regular');
      doc.setFontSize(getFontSize(7));
      doc.setTextColor(0, 0, 0);
      doc.setFillColor(245, 245, 245);
      doc.roundedRect(topicLabelX + groupWidth / 2 - 9, currentY + 4, 16, 16, 2, 2, "F");
      if (icon) {
        doc.addImage(icon, 'PNG', topicLabelX + groupWidth / 2 - 7.17, currentY + 6.17, 11.67, 11.67);
      }
      const topicText = doc.splitTextToSize(topic.topic, maxWidth);
      doc.text(topicText, topicLabelX + groupWidth / 2, currentY + 28, {align: "center"});
      topicLabelX += groupWidth + groupSpacing;
      currentX += groupSpacing;
    });
    const yTextLeyend = doc.internal.pageSize.getHeight() - 30
    const position = addText(doc, `${titleUpper}:`, [97, 97, 97], "Montserrat", "bold", "left", 0, 8, 59, yTextLeyend);
    const textWidth = doc.getTextWidth(`${titleUpper}:`);
    let xLegend = position[0] + textWidth + 8;
    let yLegend = position[1];
    legend.forEach((item, index) => {
      doc.setFillColor(...item.color)
      doc.rect(xLegend, yLegend - 6, 6, 6, 'F');
      addText(doc, item.name, [0, 0, 0], 'Montserrat', 'regular', 'left', 0, 8, xLegend + 11, yLegend);
      xLegend += doc.getTextWidth(item.name) + 19;
    })
  } else {
    // **Gráfico de barras horizontales**
    const maxPercentage = Math.ceil(
      Math.max(
        ...dataSorted.map((topic) => topic.representativity)
      )
    );
    let currentY = y;
    const barHeight = (height - 7 * dataSorted.length) / dataSorted.length;
    const spacing = 7; // Espaciado entre barras
    // Etiquetas del eje X (valores porcentuales)
    const xStep = 5; // Incrementos del eje X
    const xTicks = Math.ceil(maxPercentage / xStep);
    const xUnitWidth = width / xTicks; // Ancho entre ticks
    const tickY = currentY + 10; // Posición de las etiquetas del eje X
    for (let i = 0; i <= xTicks; i++) {
      const value = i * xStep;
      const xPosition = x + i * xUnitWidth;
      doc.setFontSize(getFontSize(8));
      doc.setFont('Montserrat', 'regular');
      doc.setTextColor(0, 0, 0);
      doc.text(`${value}%`, xPosition, tickY, {align: "right"});
    }
    currentY += 15;
    // Dibujar barras y etiquetas del eje Y (temas)
    dataSorted.forEach((item, index) => {
      const barWidth = (item.representativity / maxPercentage) * width;
      const barColor = palette[index % palette.length];
      const icon = findTopicIcon(topicIndexed, item.topic);
      // Dibujar barra
      doc.setFillColor(245, 245, 245);
      doc.roundedRect(x - 28, currentY + barHeight / 2 - 9, 18, 18, 2, 2, "F");
      doc.setFillColor(...barColor);
      doc.roundedRect(x, currentY, barWidth, barHeight, 4, 4, "F");
      doc.rect(x, currentY, 4, barHeight - 0.05, "F");
      doc.setFontSize(getFontSize(8));
      doc.setTextColor(...barColor);
      doc.setFont("Montserrat", "bold");
      doc.text(`${item.representativity.toFixed(1)}%`, x + barWidth + 5, currentY + barHeight / 2 + 3, {align: "left"});

      // Etiqueta del eje Y (tema)
      const iconX = x - 26;
      const textX = x - 32;
      doc.setFontSize(getFontSize(8));
      doc.setFont("Montserrat", "regular");
      doc.setTextColor(0, 0, 0);
      doc.text(item.topic, textX, currentY + barHeight / 2, {
        align: "right",
        maxWidth: 80
      });
      if (icon) {
        doc.addImage(icon, 'PNG', iconX, currentY + barHeight / 2 - 7, 14, 14);
      }
      currentY += barHeight + spacing;
    });
  }
};
const getSampleAnswers = (topic) => {
  const quotes = [];
  topic.subtopics.forEach((subtopic) => {
    if (subtopic.sample_answers && subtopic.sample_answers.length > 0) {
      subtopic.sample_answers.forEach((quote) => {
        if (quotes.length >= 10) {
          return;
        }
        quotes.push(quote);
      })
    }
  });
  return quotes.slice(0, Math.min(quotes.length, 10));
};
export const addResponsesAnalysis = async (doc, scheme, contentList, schemeColor, organization, color, colorS, data, pallette, partial_conclusions) => {
  const palletteColors = pallette.map(color => {
    const match = color.match(/\d+/g);
    return match ? match.map(Number) : [];
  });
  const topicIndex = buildTopicIndex(scheme);
  const topicIndexTonality = buildTopicIndex(schemeColor);
  const initPage = doc.getNumberOfPages();
  let items = [];
  const orderedQuestions = scheme.policies.filter(p => Object.keys(data).includes(p.longName)).map(p => p.longName);
  for (const [index, questionKey] of orderedQuestions.entries()) {
    let subI = 1;
    addPage(doc);
    items.push({
      'name': `QUESTION ${index + 1} - ${questionKey}`,
      'page': doc.getNumberOfPages()
    });
    addRoundedRectangle(doc, color, 0, 0, 337, 595, 168.5, 'bottom')
    addText(doc, '3. Responses', [255, 255, 255], 'Raleway', 'bold', 'left', 0, 20, 30, 50);
    addText(doc, ' Analysis', [255, 255, 255], 'Raleway', 'bold', 'left', 0, 20, 160, 50);
    const question = data[questionKey];
    addText(doc, `QUESTION ${index + 1}`, [255, 255, 255], 'Montserrat', 'regular', 'left', 0, 16, 30, 126);
    let textHeight = Infinity;
    let fontSize = 36;
    while (textHeight > 350) {
      doc.setFontSize(getFontSize(fontSize));
      textHeight = doc.splitTextToSize(questionKey, 277).length * fontSize;
      if (textHeight > 350) {
        fontSize -= 2;
      }
    }
    addText(doc, `3.${index + 1}. ${questionKey}`, [255, 255, 255], 'Raleway', 'regular', 'left', 277, fontSize, 30, 176);
    addText(doc, 'Prevalence of each topic (%)', [0, 0, 0], 'Raleway', 'bold', 'left', 0, 12, 361, 42);
    addBarGraph(doc, scheme, question.sections[1], 473, 55, 303, 485, palletteColors, topicIndex, 'horizontal')
    addPage(doc)
    addText(doc, `QUESTION ${index + 1}`, [66, 66, 66], 'Montserrat', 'bold', 'left', 0, 10, 30, 40);
    addText(doc, `${questionKey.toUpperCase()}`, [33, 33, 33], 'Raleway', 'bold', 'left', 586, 16, 30, 61);
    let lastY = doc.splitTextToSize(questionKey.toUpperCase(), 586).length * 16 + 82;
    let y = lastY;
    for (const section of question.sections) {
      const sectionName = section.sectionName;
      if (section.order === 0) {
        addText(doc, `3.${index + 1}.${subI}. ${sectionName}`, [33, 33, 33], 'Raleway', 'bold', 'left', 349, 13, 30, lastY);
        lastY += 13;
        subI++;
        section.sectionContent.forEach((subSection, index) => {
          let height = 0;
          if (index === 0) {
            lastY = height = addTextFromHtml(doc, subSection.sectionContent, 30, y + 60, 362, 'Montserrat', 10, [0, 0, 0], [255, 255, 255], true);
            y += 12;
            addRoundedRectangle(doc, [55, 54, 59], 0, y, 409, height - y, 30, 'right');
            y += 32;
            lastY += 32;
            addText(doc, subSection.sectionName, [255, 255, 255], 'Raleway', 'bold', 'left', 0, 12, 30, y);
            y += 18;
            addTextFromHtml(doc, subSection.sectionContent, 30, y, 362, 'Montserrat', 10, [255, 255, 255], [255, 255, 255], true)
          } else if (index === 1) {
            addText(doc, subSection.sectionName, [66, 66, 66], 'Raleway', 'bold', 'left', 0, 12, 30, lastY);
            lastY += 18;
            addTextFromHtml(doc, subSection.sectionContent, 30, lastY, 379, 'Montserrat', 10, [97, 97, 97], [97, 97, 97], true)
          } else if (index === 2) {
            addText(doc, subSection.sectionName, [66, 66, 66], 'Raleway', 'bold', 'left', 0, 12, 433, y - 40);
            lastY = addTextFromHtml(doc, subSection.sectionContent, 433, y - 22, 362, 'Montserrat', 10, [97, 97, 97], [97, 97, 97], true);
          } else {
            height = addTextFromHtml(doc, subSection.sectionContent, 463, lastY, 349, 'Montserrat', 10, [255, 255, 255], [255, 255, 255], true)
            addRoundedRectangle(doc, color, 433, lastY, 409, height - lastY + 56, 30, 'left');
            addText(doc, subSection.sectionName, [255, 255, 255], 'Raleway', 'bold', 'left', 0, 12, 463, lastY + 32);
            addTextFromHtml(doc, subSection.sectionContent, 463, lastY + 52, 349, 'Montserrat', 10, [255, 255, 255], [255, 255, 255], true)
          }
        })
      } else if (section.order === 1) {
        const cantPages = Math.ceil(section.additionalData?.length / 2);
        const topics = [...section.additionalData].sort((a, b) => b.representativity - a.representativity)
        let yLeft = 128;
        let yRight = 128;
        const xLeft = 30;
        const xRight = 431;
        for (let i = 0; i < cantPages * 2; i++) {
          const topic = topics[i];
          let lastY = doc.splitTextToSize(questionKey.toUpperCase(), 586).length * 16 + 100;
          if (topic) {
            const icon = findTopicIcon(topicIndexTonality, topic.topic);
            if (i % 2 === 0) {
              addPage(doc);
              addText(doc, `QUESTION ${index + 1}`, [66, 66, 66], 'Montserrat', 'bold', 'left', 0, 10, 30, 40);
              addText(doc, `${questionKey.toUpperCase()}`, [33, 33, 33], 'Raleway', 'bold', 'left', 586, 16, 30, 61);
              addText(doc, `3.${index + 1}.${subI}. ${sectionName.toUpperCase()}`, [33, 33, 33], 'Raleway', 'bold', 'left', 349, 13, 30, lastY);
              lastY += 20;
              doc.setFillColor(...palletteColors[i % palletteColors.length]);
              doc.roundedRect(xLeft, lastY - 8, 22, 22, 2, 2, 'F')
              if (icon) {
                doc.addImage(icon, 'PNG', xLeft + 2.67, lastY - 5.67, 16.67, 16.67);
              }
              addText(doc, `${topic.topic} (${topic.representativity}%)`, [97, 97, 97], 'Montserrat', 'bold', 'left', 379, 12, xLeft + 27, lastY);
              lastY += 15;
              addText(doc, `SUBTOPICS: ${topic.subtopics ? topic.subtopics.length : 0}`, [0, 0, 0], 'Montserrat', 'regular', 'left', 0, 8, xLeft + 27, lastY);
              addText(doc, `QUOTES: ${topic.count}`, [0, 0, 0], 'Montserrat', 'regular', 'left', 0, 8, 134, lastY);
              yLeft = lastY + 18;
              addText(doc, 'Summary:', [66, 66, 66], 'Montserrat', 'bold', 'left', 379, 10, xLeft, yLeft);
              yLeft += 12;
              addText(doc, topic.summary, [66, 66, 66], 'Montserrat', 'regular', 'left', 379, 10, xLeft, yLeft);
              yLeft += 18;
              yLeft += doc.splitTextToSize(topic.summary, 379).length * 12;
              let sample_answers = topic.sample_answers || getSampleAnswers(topic);
              if (sample_answers) {
                addText(doc, 'Quotes:', [66, 66, 66], 'Montserrat', 'bold', 'left', 379, 10, xLeft, yLeft);
                yLeft += 12;
                let y = yLeft;
                const colorQ = getContrastTonality(pallette[i]) === 'dark' ? [0, 0, 0] : [255, 255, 255];
                sample_answers?.forEach((answer) => {
                  const dinamicHeight = doc.splitTextToSize(answer, 361).length * 12 + 15;
                  if (y + 20 + dinamicHeight < doc.internal.pageSize.height) {
                    doc.setFillColor(...palletteColors[i % palletteColors.length]);
                    doc.roundedRect(xLeft, y, 381, dinamicHeight, 10, 10, 'F');
                    doc.setFillColor(...palletteColors[i % palletteColors.length]);
                    doc.rect(xLeft + 381 - 20, y, 20, 10, 'F');
                    const font= getFontForText(answer);
                    addText(doc, `"${answer}"`, colorQ, font, 'italic', 'left', 370, 10, xLeft + 9, y + 18);
                    y += dinamicHeight + 10;
                  }
                });
              }
              yLeft += 150;
            } else {
              yRight = lastY + 20
              doc.setFillColor(...palletteColors[i % palletteColors.length]);
              doc.roundedRect(xRight, yRight - 8, 22, 22, 2, 2, 'F');
              if (icon) {
                doc.addImage(icon, 'PNG', xRight + 2.67, yRight - 5.67, 16.67, 16.67);
              }
              addText(doc, `${topic.topic} (${topic.representativity}%)`, [97, 97, 97], 'Montserrat', 'bold', 'left', 379, 12, xRight + 27, yRight);
              yRight += 15;
              addText(doc, `SUBTOPICS: ${topic.subtopics ? topic.subtopics.length : 0}`, [0, 0, 0], 'Montserrat', 'regular', 'left', 0, 8, xRight + 27, yRight);
              addText(doc, `QUOTES: ${topic.count}`, [0, 0, 0], 'Montserrat', 'regular', 'left', 0, 8, 537, yRight);
              yRight += 20;
              addText(doc, 'Summary:', [66, 66, 66], 'Montserrat', 'bold', 'left', 379, 10, xRight, yRight);
              yRight += 12;
              addText(doc, topic.summary, [66, 66, 66], 'Montserrat', 'regular', 'left', 379, 10, xRight, yRight);
              yRight += 18;
              yRight += doc.splitTextToSize(topic.summary, 379).length * 12;
              let sample_answers = topic.sample_answers || getSampleAnswers(topic);
              if (sample_answers) {
                addText(doc, 'Quotes:', [66, 66, 66], 'Montserrat', 'bold', 'left', 379, 10, xRight, yRight);
                yRight += 12;
                let y = yRight;
                const colorQ = getContrastTonality(pallette[i]) === 'dark' ? [0, 0, 0] : [255, 255, 255];
                sample_answers?.forEach((answer) => {
                  const dinamicHeight = doc.splitTextToSize(answer, 361).length * 12 + 15;
                  if (y + 20 + dinamicHeight < doc.internal.pageSize.height) {
                    doc.setFillColor(...palletteColors[i % palletteColors.length]);
                    doc.roundedRect(xRight, y, 381, dinamicHeight, 10, 10, 'F');
                    doc.setFillColor(...palletteColors[i % palletteColors.length]);
                    doc.rect(xRight + 381 - 20, y, 20, 10, 'F');
                    const font= getFontForText(answer);
                    addText(doc, `"${answer}"`, colorQ, font, 'italic', 'left', 370, 10, xRight + 9, y + 20);
                    y += dinamicHeight + 10;
                  }
                });
              }
              yRight += 150;
              if (i % 2 === 1 && i < section.additionalData.length - 1) {
                yLeft = 127;
                yRight = 127;
              }
            }
          }
        }
      } else if (section.order === 2) {
        // DETAILED ANALYSIS PER SEGMENT
        section.sectionContent.forEach((subSection, indexSub) => {
          let lastY = 0;
          addPage(doc)
          addText(doc, `QUESTION ${index + 1}`, [66, 66, 66], 'Montserrat', 'bold', 'left', 0, 10, 30, 40);
          addText(doc, `${questionKey.toUpperCase()}`, [33, 33, 33], 'Raleway', 'bold', 'left', 586, 16, 30, 61);
          lastY += doc.splitTextToSize(questionKey.toUpperCase(), 586).length * 16 + 82;
          addText(doc, `3.${index + 1}.${subI + 1} ${sectionName}`, [33, 33, 33], 'Raleway', 'bold', 'left', 349, 13, 30, lastY);
          lastY += 20;
          addText(doc, `${subSection.sectionName}:`, [33, 33, 33], 'Raleway', 'bold', 'left', 349, 12, 30, lastY);
          lastY += 20;
          addTextFromHtml(doc, subSection.sectionContent, 30, lastY, 379, 'Montserrat', 10, [97, 97, 97], color, false, 2);
          addBarGraph(doc, scheme, subSection, 30, 255, 712, 248, palletteColors, topicIndex)
        })
        subI++;
      } else {
        addPage(doc)
        addText(doc, `QUESTION ${index + 1}`, [66, 66, 66], 'Montserrat', 'bold', 'left', 0, 10, 30, 40);
        addText(doc, `${questionKey.toUpperCase()}`, [33, 33, 33], 'Raleway', 'bold', 'left', 586, 16, 30, 61);
        lastY = doc.splitTextToSize(questionKey.toUpperCase(), 586).length * 16 + 82;
        addText(doc, `3.${index + 1}.${subI + 1} ${sectionName}`, [33, 33, 33], 'Raleway', 'bold', 'left', 349, 13, 30, lastY);
        lastY += 24;
        const images = getRandomImages(scheme, 6)
        const startX = 434;
        const startY = 101;
        const imageWidth = 204;
        const imageHeight = 154;
        try {
          for (let i = 0; i < 6; i++) {
            const row = Math.floor(i / 2);
            const col = i % 2;
            const x = startX + col * imageWidth;
            const y = startY + row * imageHeight;

            try {
              if (images.length > 0) {
                const base64Image = await convertImageUrlToBase64(images[i]);
                doc.addImage(base64Image, "WEBP", x, y, imageWidth, imageHeight);
              } else {
                doc.setFillColor(...palletteColors[i])
                doc.rect(x, y, imageWidth, imageHeight, "F");
              }
            } catch (e) {
              console.error("Error adding image:", e);
            }
          }
        } catch (e) {
          console.error('Error adding image', e);
        }
        try {
          doc.addImage(partial_conclusions, 'WEBP', 434, 101, 410, 464);
        } catch (e) {
          console.error('Error adding image', e);
        }
        addTextFromHtml(doc, section.sectionContent, 30, lastY, 379, 'Montserrat', 10, [66, 66, 66], [66, 66, 66]);
      }
    }
  }

  contentList.push({
    'name': 'Responses analysis',
    'page': initPage,
    'items': items
  });

}

export const addConclusion = async (doc, scheme, contentList, color, data, conclusion_left, conclusion_right, palette) => {
  addPage(doc);
  contentList.push({'name': 'Conclusions', 'page': doc.getNumberOfPages()});
  let y = 88;
  let x = 30;
  try {
    addText(doc, `4. ${data[0].sectionName}`, [33, 33, 33], 'Raleway', 'bold', 'left', 0, 20, x, 50);
    await addImagesConclusion(doc, scheme, conclusion_left, data, palette, 'right');
    for (let index = 0; index < data[0]?.sectionContent.length; index++) {
      const section = data[0]?.sectionContent[index];
      addText(doc, `${index + 1}- ${section.conclusionTitle}:`, [66, 66, 66], 'Raleway', 'bold', 'left', 379, 14, x, y);
      let titleHeight = 20;
      if (section.conclusionTitle.length * 7.6 > 379) {
        titleHeight = 40;
      }
      y += titleHeight;
      addText(doc, `${section.conclusionContent}`, [66, 66, 66], 'Montserrat', 'regular', 'left', 379, 10, x, y);
      y += doc.splitTextToSize(section.conclusionContent, 379).length * 12 + 27;
      if (y > 500) {
        addPage(doc)
        y = 88;
        x = 434;
        addText(doc, `4. ${data[0].sectionName}`, [33, 33, 33], 'Raleway', 'bold', 'left', 0, 20, 30, 50);
        await addImagesConclusion(doc, scheme, conclusion_right, data, palette, 'left');
      }
    }
  } catch (e) {
    console.error('Error adding conclusion images', e);
  }
}

export const addImagesConclusion = async (doc, scheme, conclusion, data, palette, side) => {
  const images = getRandomImages(scheme, 2)
  const palletteColors = palette.map(color => {
    const match = color.match(/\d+/g);
    return match ? match.map(Number) : [];
  });
  let x, y;
  if (side === 'left') {
    x = 0;
    y = 70;
  } else {
    x = 446;
    y = 70;
  }
  if (images.length > 1) {
    const image1 = await convertImageUrlToBase64(images[0]);
    const image2 = await convertImageUrlToBase64(images[1]);
    doc.addImage(image1, 'WEBP', x, y, 422, 240);
    y = 315;
    doc.addImage(image2, 'WEBP', x, y, 422, 240);
  } else {
    doc.setFillColor(...palletteColors[side === 'left' ? 2 : 0])
    doc.rect(x, y, 422, 240, 'F');
    y = 315;
    doc.setFillColor(...palletteColors[side === 'left' ? 3 : 1])
    doc.rect(x, y, 422, 240, 'F');
  }
  doc.addImage(conclusion, 'WEBP', side === 'left' ? 0 : 446, 70, 422, 490);
}
export const addRoundedRectangle = (doc, color, x, y, width, height, radius, rounded) => {
  if (rounded === 'left') {
    doc.setFillColor(color[0], color[1], color[2]);
    doc.rect(x + radius, y, width - radius, height, 'F');
    doc.rect(x + 0.1, y + radius, radius, height - 2 * radius, 'F');
    doc.circle(x + radius, y + radius, radius, 'F');
    doc.circle(x + radius, y + height - radius, radius, 'F');
  } else if (rounded === 'right') {
    doc.setFillColor(color[0], color[1], color[2])
    doc.rect(x, y, width - radius, height, 'F');
    doc.rect(x + width - radius - 0.1, y + radius, radius, height - 2 * radius, 'F');
    doc.circle(x + width - radius, y + radius, radius, 'F');
    doc.circle(x + width - radius, y + height - radius, radius, 'F');
  } else if (rounded === 'top') {
    doc.setFillColor(color[0], color[1], color[2]);
  } else if (rounded === 'bottom') {
    doc.setFillColor(color[0], color[1], color[2]);
    doc.rect(x, y, width, height - radius, 'F');
    doc.rect(x, y + height - radius, width - radius, radius, 'F');
    doc.circle(x + width - radius, y + height - radius, radius, 'F');
  }
  return doc
}
export const addText = (doc, text, color, font, style, align = 'left', maxWidth = 0, size, x, y, vignette = '') => {
  doc.setTextColor(...color);
  doc.setFontSize(getFontSize(size));
  doc.setFont(`${font}`, `${style}`);
  if (vignette === 'circle') {
    doc.circle(x - 7, y - 3.5, 1.5, 'F');
  } else if (vignette === 'circled') {
    doc.circle(x - 7, y - 3.5, 1.5, 'F');
  }
  doc.text(`${text}`, x, y, {align: `${align}`, maxWidth: `${maxWidth}`});
  return [x, y]
}
export const addTextFromHtml = (doc, htmlText, x, y, maxWidth, font, size, color, colorBold, isBreak = false, columns = 1) => {
  const parser = new DOMParser();
  const htmlContent = parser.parseFromString(htmlText, 'text/html');

  htmlContent.body.childNodes.forEach((node) => {
    if (node.nodeName === 'H2') {
      doc.setFont(font, 'bold');
      doc.setFontSize(getFontSize(size + 2));
      doc.setTextColor(colorBold[0], colorBold[1], colorBold[2]);
      doc.text(node.textContent.trim(), x, y, {maxWidth});
      y += 15; // Espacio después del encabezado
    } else if (node.nodeName === 'P') {
      const paragraphHtml = node.innerHTML;
      y = processParagraph(doc, paragraphHtml, x, y, maxWidth, font, size, color, colorBold, isBreak);
      y += 12; // Espacio entre párrafos
    } else if (node.nodeName === 'UL') {
      const yInit = y;
      node.childNodes.forEach((liNode) => {
        if (liNode.nodeName === 'LI') {
          const liHtml = liNode.innerHTML;
          y = processListItem(doc, liHtml, x, y, maxWidth, font, size, color, colorBold, 'bullet', null);
          y += 12;
          if (columns > 1) {
            if (y >= 230) {
              y = yInit;
              x = maxWidth + 54
            }
          }
        }
      });
    } else if (node.nodeName === 'OL') {
      let index = 1; // Inicializa el índice para listas ordenadas
      node.childNodes.forEach((liNode) => {
        if (liNode.nodeName === 'LI') {
          const liHtml = liNode.innerHTML;
          y = processListItem(doc, liHtml, x, y, maxWidth, font, size, color, colorBold, 'number', index);
          index++; // Incrementa el índice
          y += 12; // Espacio entre elementos de lista
        }
      });
    }
  });
  return y;
};
const processParagraph = (doc, paragraphHtml, x, y, maxWidth, font, size, color, colorBold, isBreak = false) => {
  const parser = new DOMParser();
  const paragraphContent = parser.parseFromString(paragraphHtml, 'text/html');
  let currentX = x;
  let currentY = y;

  paragraphContent.body.childNodes.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE || node.nodeName === 'STRONG') {
      const isBold = node.nodeName === 'STRONG';
      doc.setFont(font, isBold ? 'bold' : 'regular');
      doc.setFontSize(getFontSize(size));
      doc.setTextColor(isBold ? colorBold[0] : color[0], isBold ? colorBold[1] : color[1], isBold ? colorBold[2] : color[2]);
      const text = node.textContent.trim();
      const words = text.split(' ');
      words.forEach((word, index) => {
        const isLastWord = index === words.length - 1;
        const hasPeriod = word.endsWith('.');
        const textWidth = doc.getTextWidth(word + (isLastWord ? ' ' : ' '));
        if (currentX + textWidth > x + maxWidth) {
          // Saltar a la siguiente línea si no cabe
          currentY += 12;
          currentX = x;
        }
        doc.text(word + (isLastWord ? ' ' : ' '), currentX, currentY);
        currentX += textWidth;
        // Si la palabra termina con un punto y es la última palabra, forzar salto de línea
        if (hasPeriod && isBreak) {
          currentY += 24; // Incrementar la posición Y para separar el párrafo
          currentX = x; // Reiniciar X para la siguiente línea
        }
      });
    }
  });
  return currentY + 12; // Devuelve la nueva posición Y con separación adicional
};
const processListItem = (doc, listItemHtml, x, y, maxWidth, font, size, color, colorBold, type = 'bullet', index = null) => {
  const parser = new DOMParser();
  const listItemContent = parser.parseFromString(listItemHtml, 'text/html');
  let currentX = x
  let currentY = y;


  if (type === 'bullet') {
    // Dibujar viñeta para listas no ordenadas
    doc.setFillColor(...colorBold);
    doc.circle(x + 3, currentY - 3, 2, 'F');
  } else if (type === 'number') {
    // Dibujar número para listas ordenadas
    doc.setFont(font, 'bold');
    doc.setFontSize(getFontSize(size));
    doc.setTextColor(colorBold[0], colorBold[1], colorBold[2]);
    doc.text(`${index}- `, x, currentY, {align: 'left'});
    currentX += 3; // Ajustar posición X después del número
  }

  listItemContent.body.childNodes.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE || node.nodeName === 'STRONG') {
      const isBold = node.nodeName === 'STRONG';
      doc.setFont(font, isBold ? 'bold' : 'regular');
      doc.setFontSize(getFontSize(size));
      doc.setTextColor(isBold ? colorBold[0] : color[0], isBold ? colorBold[1] : color[1], isBold ? colorBold[2] : color[2]);
      const text = node.textContent.trim();
      const words = text.split(' ');
      words.forEach((word, index) => {
        const textWidth = doc.getTextWidth(word + (index < words.length - 1 ? ' ' : ' '));
        if (currentX + textWidth > x + maxWidth) {
          currentY += 12;
          currentX = x;
        }
        doc.text(word + (index < words.length - 1 ? ' ' : ' '), currentX + 9, currentY);
        currentX += textWidth;
      });
    }
  });
  return currentY + 12;
};
const addGalleryImages = async (doc, contentList, scheme, colorP, open_question_analysis, itemGallery) => {
  let q = 1;
  const itemsPerPage = 6;
  const horizontalSpacing = 16;
  addPage(doc);
  contentList.push({'name': 'Gallery', 'page': doc.getNumberOfPages()});
  for (const [questionName, questionData] of Object.entries(open_question_analysis)) {
    const schemeQuestion = scheme.policies.find(policy => policy.longName === questionName);
    if (schemeQuestion) {
      const topicsWithImages = (schemeQuestion.topics || []).filter(topic => topic.image_url);
      if (topicsWithImages.length > 0) {
        const topicBlocks = [];
        for (let i = 0; i < topicsWithImages.length; i += itemsPerPage) {
          topicBlocks.push(topicsWithImages.slice(i, i + itemsPerPage));
        }
        for (const block of topicBlocks) {
          const pageIndex = topicBlocks.indexOf(block);
          if (pageIndex > 0) addPage(doc);
          doc.setFont('Raleway', 'bold');
          doc.setFontSize(getFontSize(20));
          doc.setTextColor(33, 33, 33);
          doc.text('5. Gallery', 30, 50);
          doc.setFont('Montserrat', 'regular');
          doc.setFontSize(getFontSize(10));
          doc.setTextColor(66, 66, 66);
          doc.text(`QUESTION ${q}`, 30, 71);
          doc.setFont('Raleway', 'bold');
          doc.setTextColor(33, 33, 33);
          doc.text(questionName.toUpperCase(), 30, 85, {maxWidth: 586});

          for (const topic of block) {
            const index = block.indexOf(topic);
            const imgWidth = 250;
            const imgHeight = 170;
            const x = (index % 3) * (imgWidth + horizontalSpacing) + 30;
            const y = Math.floor(index / 3) * 220 + 120;
            const imgX = x + 2.5;
            const imgY = y + 2.5;
            const imageUrl = topic.image_url;
            if (imageUrl) {
              const imageBase64 = await convertImageUrlToBase64(imageUrl);
              doc.addImage(imageBase64, 'JPEG', imgX, imgY, imgWidth, imgHeight);
              doc.addImage(itemGallery, 'JPEG', imgX, imgY, imgWidth, imgHeight + 2);
            }
            doc.setFontSize(getFontSize(12));
            doc.setTextColor(66, 66, 66);
            doc.text(topic.topic, x + 3, y + imgHeight + 20, {align: 'left'});
          }
        }
        if (q < Object.entries(open_question_analysis).length){
          addPage(doc)
        }
        q++;
      }
    }
  }

  doc.setPage(2);
  let x = 450;
  let y = 104;
  contentList.forEach((item) => {
    doc.setFont('Raleway', 'semibold');
    doc.setFontSize(getFontSize(10));
    doc.setTextColor(255, 255, 255);
    const textWidth = doc.getTextWidth(item.name);
    const remainingWidth = 334 - textWidth;
    const dashWidth = doc.getTextWidth('-');
    const numDashes = Math.floor(remainingWidth / dashWidth);
    const dashLine = '-'.repeat(numDashes);
    doc.text(`${item.name} ${dashLine}`, x, y, {align: 'left'});
    doc.text(`${item.page}.`, doc.internal.pageSize.getWidth() - 37, y, {align: 'right'});

    y += 14;
    item.items?.forEach((subItem) => {
      doc.setFont('Montserrat', 'regular');
      doc.setFillColor(255, 255, 255)
      doc.circle(x + 6, y - 4, 1.5, 'F');
      const lines = doc.splitTextToSize(subItem.name, 334).length;
      doc.text(`${subItem.name}`, x + 15, y, {align: 'left', maxWidth: 318});
      if (subItem.page !== '') {
        doc.text(`${subItem.page}.`, doc.internal.pageSize.getWidth() - 37, y, {align: 'right'});
      }
      if (item.name === 'Responses analysis') {
        const remainingWidth = 12;
        const dashWidth = doc.getTextWidth('-');
        const numDashes = Math.floor(remainingWidth / dashWidth);
        const dashLine = '-'.repeat(numDashes);
        doc.text(`${dashLine}`, doc.internal.pageSize.getWidth() - 66, y, {align: 'left'});
      }
      y += 12 * lines + 4;
    })
    y += 12;
  });
};

export const exportPDF = async (scheme, schemeColor, dataReport, description, organization, primary_color, secondary_color, palette, convertSvgToPngBase64, image) => {
  let doc = new jspdf.jsPDF('l', "px", [842, 595], true);
  const urlMontserratR = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/montserrat-regular.ttf';
  const urlMontserratB = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/montserrat-bold.ttf';
  const urlMontserratI = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/montserrat-italic.ttf';
  const urlRalewayR = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/raleway-regular.ttf'
  const urlRalewayB = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/raleway-bold.ttf'
  const urlRalewaySB = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/raleway-semibold.ttf'
  const urlNotoSansA = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/noto-sans-arabic.ttf'
  const urlNotoSansSC = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/noto-sans-sc.ttf'
  const urlNotoSansJP = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/noto-sans-jp.ttf'
  const urlNotoSansDV = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/noto-sans-devanagari.ttf'
  const urlNotoSansThai = 'https://crowd-view.s3.us-east-1.amazonaws.com/fonts-pdf/noto-sans-thai.ttf'
  try {
    const montRBase64 = await extractWoff2FromURL(urlMontserratR);
    const montBBase64 = await extractWoff2FromURL(urlMontserratB);
    const montIBase64 = await extractWoff2FromURL(urlMontserratI);
    const ralewRBase64 = await extractWoff2FromURL(urlRalewayR);
    const ralewBBase64 = await extractWoff2FromURL(urlRalewayB);
    const ralewSBBase64 = await extractWoff2FromURL(urlRalewaySB);
    const notoSansABase64 = await extractWoff2FromURL(urlNotoSansA);
    const notoSansSCBase64 = await extractWoff2FromURL(urlNotoSansSC);
    const notoSansJPBase64 = await extractWoff2FromURL(urlNotoSansJP);
    const notoSansDVBase64 = await extractWoff2FromURL(urlNotoSansDV);
    const notoSansThaiBase64 = await extractWoff2FromURL(urlNotoSansThai);
    doc.addFileToVFS('montserrat-regular.ttf', montRBase64);
    doc.addFileToVFS('montserrat-bold.ttf', montBBase64);
    doc.addFileToVFS('montserrat-italic.ttf', montIBase64);
    doc.addFileToVFS('raleway-regular.ttf', ralewRBase64);
    doc.addFileToVFS('raleway-bold.ttf', ralewBBase64);
    doc.addFileToVFS('raleway-semibold.ttf', ralewSBBase64);
    doc.addFileToVFS('noto-sans-arabic.ttf', notoSansABase64);
    doc.addFileToVFS('noto-sans-sc.ttf', notoSansSCBase64);
    doc.addFileToVFS('noto-sans-jp.ttf', notoSansJPBase64);
    doc.addFileToVFS('noto-sans-devanagari.ttf', notoSansDVBase64);
    doc.addFileToVFS('noto-sans-thai.ttf', notoSansThaiBase64);
    doc.addFont('montserrat-regular.ttf', 'Montserrat', 'regular');
    doc.addFont('montserrat-bold.ttf', 'Montserrat', 'bold');
    doc.addFont('montserrat-italic.ttf', 'Montserrat', 'italic');
    doc.addFont('raleway-regular.ttf', 'Raleway', 'regular');
    doc.addFont('raleway-bold.ttf', 'Raleway', 'bold');
    doc.addFont('raleway-semibold.ttf', 'Raleway', 'semibold');
    doc.addFont('noto-sans-arabic.ttf', 'Noto Sans Arabic', 'italic');
    doc.addFont('noto-sans-sc.ttf', 'Noto Sans SC', 'italic');
    doc.addFont('noto-sans-jp.ttf', 'Noto Sans JP', 'italic');
    doc.addFont('noto-sans-devanagari.ttf', 'Noto Sans Devanagari', 'italic');
    doc.addFont('noto-sans-thai.ttf', 'Noto Sans Thai', 'italic');
  } catch (e) {
    console.error("Error adding fonts")
  }
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const rectX = 0;
  const centerWidth = pageWidth / 2;
  const centerHeight = pageHeight / 2;
  let colorP = hexToRgb(primary_color);
  let colorS = hexToRgb(secondary_color);
  const logoProsperia = await convertImageUrlToBase64('https://crowd-view.s3.us-east-1.amazonaws.com/prosperia_foot_page.png');
  const middleCircle = await convertImageUrlToBase64('https://crowd-view.s3.us-east-1.amazonaws.com/summary_grid.svg');
  const triangle = await convertImageUrlToBase64('https://crowd-view.s3.us-east-1.amazonaws.com/triangle.png');
  const conclusion_left = await convertImageUrlToBase64('https://crowd-view.s3.us-east-1.amazonaws.com/conclusion_left.png');
  const conclusion_right = await convertImageUrlToBase64('https://crowd-view.s3.us-east-1.amazonaws.com/conclusion_right.png');
  const partConclusions = await convertImageUrlToBase64('https://crowd-view.s3.us-east-1.amazonaws.com/partial_conclusions.png');
  const itemGallery = await convertImageUrlToBase64('https://crowd-view.s3.us-east-1.amazonaws.com/gallery.png');
  const gridOrg = await convertSvgToPngBase64(middleCircle, primary_color, 440, 404);
  let imgOrg;
  if (image) {
    imgOrg = await convertImageUrlToBase64(image)
  } else {
    imgOrg = await convertImageUrlToBase64(organization.attrs.profile_img_url)
  }
  let y = 0;
  let contentList = [];
  await generateFrontPage(doc, scheme, organization, {
    x: centerWidth,
    y: 335
  }, colorP, imgOrg, logoProsperia, palette);
  await generateTableOfContents(doc, colorP, imgOrg, gridOrg, description, organization);
  addExecutiveSummary(doc, contentList, colorP, colorS, dataReport.executive_summary.sections);
  await addRelevantSegments(doc, scheme, contentList, colorP, getSegments(dataReport.segments_analysis.analysisReport || dataReport.segments_analysis.sections), palette, triangle);
  await addResponsesAnalysis(doc, scheme, contentList, schemeColor, organization, colorP, colorS, dataReport.open_question_analysis, palette, partConclusions);
  await addConclusion(doc, scheme, contentList, colorP, dataReport.conclusions.sections, conclusion_left, conclusion_right, palette);
  await addGalleryImages(doc, contentList, scheme, colorP, dataReport.open_question_analysis, itemGallery);
   doc.save("report.pdf");
}

function getFontSize(px) {
  return px / 0.75;
}

function getSegments(data) {
  if (Array.isArray(data)) {
    return data;
  }
  if (typeof data === 'object' && data !== null) {
    return [data];
  }
  return [];
}

function hexToRgb(hex) {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
}

async function convertImageUrlToBase64(url) {
  try {
    const response = await axiosGeneric.get(url, {responseType: 'arraybuffer'});
    return btoa(
      new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
  } catch (error) {
    console.error("Error al cargar la imagen:", error);
  }
}

async function extractWoff2FromURL(fontUrl) {
  try {
    const response = await axiosGeneric.get(fontUrl, {responseType: 'arraybuffer'});
    return btoa(
      new Uint8Array(response.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        '',
      ),
    );
  } catch (error) {
    console.error("Error al cargar la fuente:", error);
  }
}

export function getRandomImages(scheme, count = 10) {
  const allImages = [];
  for (const policy of scheme.policies) {
    for (const topic of policy.topics) {
      if (topic.image_url) {
        allImages.push(topic.image_url);
      }
    }
  }
  if (allImages.length <= count) {
    return [...allImages];
  }

  const selectedImages = [];
  const availableImages = [...allImages];

  for (let i = 0; i < count; i++) {
    const randomIndex = Math.floor(Math.random() * availableImages.length);
    selectedImages.push(availableImages[randomIndex]);
    availableImages.splice(randomIndex, 1);
  }
  return selectedImages;
}

function addUniqueObject(arr, newObject) {
  if (!arr.some(obj => obj.name === newObject.name)) {
    arr.push(newObject);
  }
  return arr;
}

function buildTopicIndex(scheme) {
  const topicIndex = new Map();
  for (const policy of scheme.policies) {
    for (const topic of policy.topics) {
      topicIndex.set(topic.topic, topic.icon);
    }
  }
  return topicIndex;
}

function findTopicIcon(topicIndex, searchText) {
  return topicIndex.get(searchText) || null;
}

const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
function detectScript(text) {
  const scripts = [
    { name: "Latin", regex: /[\u0041-\u007A]/g },
    { name: "Cyrillic", regex: /[\u0400-\u04FF]/g },
    { name: "Arabic", regex: /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/g },
    { name: "Chinese", regex: /[\u4E00-\u9FFF]/g },
    { name: "Japanese", regex: /[\u3040-\u30FF\u31F0-\u31FF]/g },
    { name: "Devanagari", regex: /[\u0900-\u097F\uA8E0-\uA8FF]/g },
    { name: "Thai", regex: /[\u0E00-\u0E7F]/g }
  ];

  let counts = {};

  for (let script of scripts) {
    let matches = text.match(script.regex);
    if (matches) {
      counts[script.name] = (counts[script.name] || 0) + matches.length;
    }
  }
  let detected = Object.entries(counts).sort((a, b) => b[1] - a[1]);

  return detected.length > 0 ? detected[0][0] : "Unknown";
}

function getFontForText(text) {
  const fontMap = {
    "Arabic": "Noto Sans Arabic",
    "Chinese": "Noto Sans SC",
    "Japanese": "Noto Sans JP",
    "Devanagari": "Noto Sans Devanagari",
    "Thai": "Noto Sans Thai",
    "Latin": "Montserrat",
    "Cyrillic": "Montserrat"
  };

  const script = detectScript(text);
  return fontMap[script] || "Montserrat"; // Default fallback
}
