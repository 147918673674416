import {Box, Grid, Typography} from "@mui/material";
import {useTranslation} from "../../../providers/TranslationProvider";
import {useEffect, useState} from "react";
import {QAHint} from "./QAHint";

export const QAHints = ({
                          setQuestion,
                          setIsLoading,
                          hints
                        }) => {
  const hintColor = [
    {
      backgroundColor: '#FFCDD2',
      color: '#F44336'
    },
    {
      backgroundColor: '#D1C4E9',
      color: '#673AB7'
    },
    {
      backgroundColor: '#B3E5FC',
      color: '#03A9F4'
    },
    {
      backgroundColor: '#F0F4C3',
      color: '#9E9D24'
    }
  ]

  const {t} = useTranslation();
  const [hintCardsContent, setHintCardsContent] = useState([]);


  useEffect(() => {
    setIsLoading(true);
    setHintCardsContent([]);
    if (hints?.length > 0) {
      setHintCardsContent(hints.map((hint, index) => {
        if (!!hint.icon && typeof hint.icon !== 'string') {
          return hint;
        }
        let parsedIcon = null;
        try {
          parsedIcon = atob(hint.icon);
        } catch (e) {
          return hint;
        }
        return {
          ...hint,
          icon: (
            <Box sx={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              width: '32px',
              height: '32px',
              minHeight: '32px',
              minWidth: '32px',
              maxHeight: '32px',
              maxWidth: '32px',
              borderRadius: '50%',
              backgroundColor: hintColor[index % hintColor.length].backgroundColor,
            }}>
              <svg
                width="18px"
                height="18px"
                fill={hintColor[index % hintColor.length].color}
                dangerouslySetInnerHTML={{__html: parsedIcon}}
              />
            </Box>
          )
        };
      }));

    }
    setIsLoading(false);
  }, [hints]);

  return (
    <Grid container direction='row' columnGap={'16px'} rowGap={'8px'} sx={{
      width: '100%'
    }}>
      {(hints?.length > 0) && (
        <Grid sx={{width: '100%', mt: '24px', mb: '4px'}} container item
              direction={'column'}>
          <Grid sx={{width: '100%', justifyContent: 'flex-start'}} item>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontSize: '10px',
              fontWeight: 600,
              textAlign: 'left',
              color: '#000000',
              letterSpacing: '0.005em'
            }}>{t('explore_ideas')}</Typography>
          </Grid>
          <Grid item
                sx={{width: '100%', justifyContent: 'flex-start', mt: '8px'}}>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontSize: '10px',
              fontWeight: 400,
              textAlign: 'left',
              color: '#616161',
              letterSpacing: '0.0025em'
            }}>{t('qa_hints_description')}</Typography>
          </Grid>
        </Grid>)}
      {hintCardsContent.length > 0 ? hintCardsContent.map((item, index) => (
        <Grid container item sx={{width: '100%'}}
              key={`hint-card-${index}`}>
          <QAHint
            title={item.title}
            question={item.question}
            icon={item.icon}
            setQuestion={setQuestion}
          />
        </Grid>
      )) : null}

    </Grid>
  )
}
