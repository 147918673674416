import * as _ from "lodash";
import {Badge, Button, Menu, Typography} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import {useDispatch, useSelector} from "react-redux";
import {MuiChip, MuiCloseIcon, MuiMenuItem} from "./styles/ActiveFilters";
import {
  setBarChartFilter,
  setInsightsFilter,
  setSegmentationFilter,
  setSimilarityFilter,
  setStatisticFilter,
  setTreeMapFilter
} from "../../../../../store/appSlice";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {normalizeString} from "../../../../../utils/text";


export const ActiveFiltersNotification = ({
                                            segmentationVariables,
                                            statisticVariables,
                                            policies
                                          }) => {
  const dispatch = useDispatch();
  const categoryFilter = useSelector(state => state.app.segmentationFilter)

  const statisticFilter = useSelector(state => state.app.statisticFilter);

  const similarityQuery = useSelector(state => state.app.similarityQuery);

  const similarityFilter = useSelector(state => state.app.similarityFilter)

  const activeInsightsFilter = useSelector(state => state.app.insightsFilter);

  const segmentationCategories = useSelector(state => state.app.segmentationCategories);

  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const barChartFilter = useSelector(state => state.app.barChartFilter);

  const scheme = useSelector(state => state.app.targetScheme);

  const [filterListAnchorEl, setFilterListAnchorEl] = useState(null);

  const [amountActiveFilters, setAmountActiveFilters] = useState(0);


  const {t} = useTranslation();

  const renderSegmentationFilters = () => {
    const localCategoryFilter = {...categoryFilter, ...barChartFilter}
    return segmentationVariables
      .filter(variable => Object.keys(localCategoryFilter).includes(variable.propName)
        && localCategoryFilter[variable.propName]?.length > 0 && localCategoryFilter[variable.propName]?.length < (segmentationCategories?.[variable.propName]?.length || 1000))
      .map((variable, index) => {
        return (
          <MuiMenuItem key={`menu-item-segmentation-filter-${index}`}>
            <MuiChip key={`segmentation-filter-${index}`}
                     deleteIcon={<MuiCloseIcon/>} onDelete={() => {
              const newCategoryFilter = {
                ...categoryFilter
              };
              newCategoryFilter[variable.propName] = [];
              dispatch(setSegmentationFilter(newCategoryFilter));
              const newBarChartFilter = {
                ...barChartFilter
              }
              newBarChartFilter[variable.propName] = [];
              dispatch(setBarChartFilter(newBarChartFilter));
            }}
                     label={`${variable.label}: ${categoryFilter[variable.propName].join(', ')}`}/>
          </MuiMenuItem>
        );
      });
  }

  const findMinDecimalPlaces = (low, high) => {
    let decimalPlaces = 0;
    while (parseFloat(low.toFixed(decimalPlaces)) === parseFloat(high.toFixed(decimalPlaces))) {
      decimalPlaces++;
    }
    return decimalPlaces;
  };

  const renderStatisticFilters = () => {
    return statisticVariables
      .filter(variable => Object.keys(statisticFilter).includes(variable.propName)
        && statisticFilter[variable.propName]?.low || statisticFilter[variable.propName]?.high)
      .map((variable, index) => {
        const low = statisticFilter[variable.propName]?.low || 0;
        const high = statisticFilter[variable.propName]?.high || 0;
        const minDecimals = findMinDecimalPlaces(low, high);
        return (
          <MuiMenuItem key={`menu-item-statistic-filter-${index}`}>
            <MuiChip key={`statistic-filter-${index}`}
                     deleteIcon={<MuiCloseIcon/>}
                     onDelete={() => {
                       const newStatisticFilter = {
                         ...statisticFilter,
                       };
                       delete newStatisticFilter[variable.propName];
                       dispatch(setStatisticFilter(newStatisticFilter));
                     }}
                     label={` ${low.toFixed(minDecimals).toLocaleString('en-US')} < ${variable.label} < ${high.toFixed(minDecimals).toLocaleString('en-US')}`}/>
          </MuiMenuItem>
        );
      });
  }

  const renderAnswerFilters = () => {
    return scheme.policies.filter(
      policy => Object.keys(treeMapFilter).includes(policy.id) && Object.keys(treeMapFilter[policy.id]).some(key => treeMapFilter[policy.id][key].length > 0)
    ).map((policy, index) => {
        const selectedTopic = policy.topics.map(topic => {
          const topicLabels = treeMapFilter[policy.id][policy.answerVar + "_label"]?.map(tl => normalizeString(tl || '')) || [];
          const subtopicsFound = topic.subtopics?.filter(st => topicLabels.includes(normalizeString(st.topic))) || [];

          if (topicLabels.includes(normalizeString(topic.topic))) {
            return topic; // Return the parent topic if it matches directly
          } else if (subtopicsFound.length > 1) {
            return topic; // Return the parent topic if multiple subtopics are found
          } else if (subtopicsFound.length === 1) {
            return subtopicsFound[0]; // Store the selected subtopic
          }
          return null;
        }).find(topic => topic !== null); // If no parent topic is found, return the subtopic directly

        return (
          <MuiMenuItem key={`menu-item-answer-filter-${index}`}>
            <MuiChip key={`answer-filter-${index}`} deleteIcon={<MuiCloseIcon/>}
                     onDelete={() => {
                       const newTreeMapFilter = {
                         ...treeMapFilter,
                         [policy.id]: {}
                       };
                       dispatch(setTreeMapFilter(newTreeMapFilter));
                     }}
                     label={`${policy.name}: ${selectedTopic?.topic}`}/>
          </MuiMenuItem>
        )
      }
    )
  }

  const renderInsightsFilters = () => {
    return scheme.policies.filter(
      policy => Object.keys(activeInsightsFilter).includes(policy.id) && Object.keys(activeInsightsFilter[policy.id]).length > 0
    ).map((policy, index) => {
        return policy.customSegmentations
          ?.segmentations
          ?.filter(
            sv => Object.keys(activeInsightsFilter[policy.id]).includes(sv.classificationVariable)
              && activeInsightsFilter[policy.id][sv.classificationVariable]?.length > 0
          )
          .map((segmentation, index) => {
              return (
                <MuiMenuItem key={`menu-item-insights-filter-${index}`}>
                  <MuiChip key={`insights-filter-${index}`}
                           deleteIcon={<MuiCloseIcon/>}
                           onDelete={() => {
                             const newInsightsFilter = _.cloneDeep(activeInsightsFilter);
                             newInsightsFilter[policy.id][segmentation.classificationVariable] = [];
                             dispatch(setInsightsFilter(newInsightsFilter));
                           }}
                           label={`${segmentation.label} (${policy.name}): ${activeInsightsFilter[policy.id][segmentation.classificationVariable].join(', ')}`}/>
                </MuiMenuItem>
              );
            }
          ) || [];
      }
    ).reduce((acc, curr) => [...acc, ...curr], []);
  }

  const renderSimilarityFilters = () => {
    return scheme.policies
      .filter(policy => Object.keys(similarityFilter).includes(policy.id)
        && similarityFilter[policy.id]?.distance?.low || similarityFilter[policy.id]?.distance?.high && !!similarityQuery[policy.id])
      .map((policy, index) => {
        const low = similarityFilter[policy.id]?.distance?.low || 0;
        const high = similarityFilter[policy.id]?.distance?.high || 0;
        const minDecimals = findMinDecimalPlaces(low, high);
        return (
          <MuiMenuItem key={`menu-item-similarity-filter-${index}`}>
            <MuiChip key={`statistic-filter-${index}`}
                     deleteIcon={<MuiCloseIcon/>}
                     onDelete={() => {
                       const newSimilarityFilter = {
                         ...similarityFilter,
                         [policy.id]: null
                       }
                       dispatch(setSimilarityFilter(newSimilarityFilter));
                     }}
                     label={`${policy.name}: ${low.toFixed(minDecimals).toLocaleString('en-US')} < Similarity < ${high.toFixed(minDecimals).toLocaleString('en-US')}`}/>
          </MuiMenuItem>
        );
      });
  }

  const activeFilters = useMemo(() => {
    return [
      ...renderSegmentationFilters(),
      ...renderStatisticFilters(),
      ...renderAnswerFilters(),
      ...renderInsightsFilters(),
      ...renderSimilarityFilters(),
    ];
  }, [categoryFilter, statisticFilter, treeMapFilter, activeInsightsFilter, similarityFilter, similarityQuery, segmentationCategories]);

  // Update the active filter count
  useEffect(() => {
    const amountFilters = activeFilters?.length || 0;
    setAmountActiveFilters(amountFilters);
    if (amountFilters === 0) {
      setFilterListAnchorEl(null);
    }
  }, [activeFilters]);


  return (
    <Badge badgeContent={amountActiveFilters} overlap={'rectangular'} sx={{
      '& .MuiBadge-badge': {
        backgroundColor: '#E5DA55',
        color: '#212121',
        fontFamily: 'Montserrat',
        fontSize: '10px'
      },
    }}>
      <Button
        variant={'outlined'}
        startIcon={
          <TuneIcon
            sx={{
              color: '#757575',
              width: '16px',
              height: '16px'
            }}/>
        }
        sx={{
          position: 'relative',
          color: '#757575',
          width: '146px',
          height: '32px',
          borderRadius: '16px',
          border: '1px solid #E0E0E0',
          fontFamily: 'Montserrat',
          textTransform: 'uppercase',
          fontWeight: 600,
          letterSpacing: '0.015em',
          alignItems: 'center',
          lineHeight: '12px',
          fontSize: '10px',
          fontStyle: 'normal',
          padding: '10px 8px 10px 8px'
        }}
        disabled={amountActiveFilters === 0}
        onClick={(evt) => setFilterListAnchorEl(evt.currentTarget)}
      >
        {t('applied_filters')}
      </Button>
      <Menu
        anchorEl={filterListAnchorEl}
        open={Boolean(filterListAnchorEl)}
        onClose={() => setFilterListAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MuiMenuItem sx={{pointer: 'default'}}>
          <Typography sx={{
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '12px',
            textTransform: 'uppercase',
            letterSpacing: '0.015em',
            color: '#616161',
            textAlign: 'left',
            mb: '10px'
          }}>
            {t('applied_filters')}
          </Typography>
        </MuiMenuItem>
        {activeFilters}
      </Menu>
    </Badge>
  )
}
