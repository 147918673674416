// React and React Hooks
import React from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';

// MUI Components
import {Drawer, Grid, Hidden, Toolbar} from '@mui/material';

// MUI Icons
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

// Redux
import {useSelector} from 'react-redux';

// Custom Components, Hooks, and Contexts
import UserMenu from 'components/views/login/UserMenu';
import {useAuth} from 'components/providers/AuthProvider';
import LanguageSelector from './LanguageSelector';
import NavigationMenu from './NavigationMenu';
import OrgInfo from './OrgInfo';

// Styles
import {
  drawerStyle,
  MuiAppBar,
  MuiContainer,
  MuiDivider,
  MuiGridContainer,
  MuiGridScheme,
  MuiIconButton
} from './styles/header';
import {
  breadBoxRight,
  infoIcon, MuiPDFIcon,
  MuiScenarioIcon,
  MuiSettingsIcon
} from "./styles/navigationMenu";
import InfoButtonWithPopover from "./InfoButtonWithPopover";
import { usePermissions } from '../hooks/usePermissions';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [schemeName, setSchemeName] = React.useState(null)

  const { isAuthenticated } = useAuth();
  const { hasPermissionFor } = usePermissions();
  const location = useLocation();
  const isTreeView = location.pathname.split('/').includes('analysis')
  const isSpecificationView = location.pathname.includes('/specification');
  const isScenarioView = location.pathname.includes('/analysis');
  const navigate = useNavigate();
  const orgId = location.pathname.split('/')[2];
  const schemeId = location.pathname.split('/')[4];
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  const targetScheme = useSelector(state => state.app.targetScheme)

  const goToReportPDF = () => navigate(`/orgs/${orgId}/scenarios/${schemeId}/analysis/report`);

  React.useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  React.useEffect(() => {
    if (targetScheme.name && targetScheme.name !== '') {
      setSchemeName(targetScheme.name)
    }
  }, [targetScheme.name])

  React.useEffect(() => {
    if (!isTreeView) {
      setSchemeName(null)
    }
  }, [isTreeView]);

  return (
    <>
      { isAuthenticated && (
        <MuiAppBar position='static'>
          <MuiContainer fixed >
            <Toolbar variant='dense'>
              <Hidden smUp>
                <MuiIconButton
                  edge='start'
                  color='inherit'
                  aria-label='menu'
                  onClick={handleDrawerToggle}
                >
                  {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                </MuiIconButton>
              </Hidden>
              {isAuthenticated && (
                <>
                  <OrgInfo />
                  <Hidden xsDown>
                    <MuiDivider orientation='vertical' flexItem />
                    <NavigationMenu location={location} />
                    {isTreeView && (
                      <Grid container sx={{width:'fit-content'}} justifyContent={'flex-start'}>
                        <InfoButtonWithPopover sx={infoIcon} scheme={targetScheme} />
                      </Grid>
                    )}
                  </Hidden>
                </>
              )}
              {isAuthenticated && (
                <Hidden smUp>
                  <Drawer
                    variant='temporary'
                    anchor='left'
                    open={drawerOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                    PaperProps={{
                      className: drawerStyle,
                    }}
                  >
                    <Toolbar variant='dense' />
                    <Grid container direction='column' justifyContent='space-between' item xs>
                      <NavigationMenu location={location} column={true} />
                    </Grid>
                  </Drawer>
                </Hidden>
              )}
              {isAuthenticated && (
                <MuiGridContainer container>
                  <Grid item
                        container
                        direction="row"
                        alignItems="center" justifyContent='flex-end'>
                    {isSpecificationView && (
                      <Grid item >
                        <MuiIconButton
                          component={NavLink}
                          to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios/${
                            location.pathname.split('/')[4]
                          }/analysis`}
                          disableRipple
                          sx={breadBoxRight}>
                          <MuiScenarioIcon/>
                        </MuiIconButton>
                      </Grid>
                    ) }
                    {isAuthenticated && targetScheme?.reportUrl && isScenarioView && (
                      <Grid item sx={{marginRight: '20px'}}>
                        <MuiIconButton
                          onClick={goToReportPDF}
                          disableRipple
                          sx={breadBoxRight}>
                          <MuiPDFIcon/>
                        </MuiIconButton>
                      </Grid>
                    )}
                    {isScenarioView && hasPermissionFor('update_schemes') && (
                      <Grid item >
                        <MuiIconButton
                          component={NavLink}
                          to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios/${
                            location.pathname.split('/')[4]
                          }/specification`}
                          disableRipple
                          sx={breadBoxRight}>
                          <MuiSettingsIcon/>
                        </MuiIconButton>
                      </Grid>
                    )}
                    <Grid item>
                      <LanguageSelector textColor='white'/>
                    </Grid>
                    <Grid item>
                      <UserMenu/>
                    </Grid>
                  </Grid>
                </MuiGridContainer>
              )}
            </Toolbar>
          </MuiContainer>
        </MuiAppBar>
      )}
    </>
  );
};

export default Header;
